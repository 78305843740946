import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

import { OfferRequestData } from '../offerRequest/types';

export interface CustomerOffersState {
    customers: Customer[];
    selectedCustomer?: Customer['id'];
    offerRequests: OfferRequestData[];
}

export interface Customer {
    id: string;
    name: string;
    user: string;
    customUser: boolean;
}

// Action types
export const GET_CUSTOMERS = 'customer-offers:GET_CUSTOMERS';
export const GET_CUSTOMERS_SUCCESS = 'customer-offers:GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'customer-offers:GET_CUSTOMERS_ERROR';
export const SELECT_CUSTOMER = 'customer-offers:SELECT_CUSTOMER';
export const GET_OFFER_REQUESTS = 'customer-offers:GET_OFFER_REQUESTS';
export const GET_OFFER_REQUESTS_SUCCESS = 'customer-offers:GET_OFFER_REQUESTS_SUCCESS';
export const GET_OFFER_REQUESTS_ERROR = 'customer-offers:GET_OFFER_REQUESTS_ERROR';

// Actions

export interface GetCustomersAction extends Action {
    type: typeof GET_CUSTOMERS;
}

export interface GetCustomersSuccessAction extends Action {
    type: typeof GET_CUSTOMERS_SUCCESS;
    payload: Customer[];
}

export interface GetCustomersErrorAction extends Action {
    type: typeof GET_CUSTOMERS_ERROR;
    error: AjaxError;
}

export interface SelectCustomerAction extends Action {
    type: typeof SELECT_CUSTOMER;
    payload: Customer['id'];
}

export interface GetOfferRequestsAction extends Action {
    type: typeof GET_OFFER_REQUESTS;
}

export interface GetOfferRequestsSuccessAction extends Action {
    type: typeof GET_OFFER_REQUESTS_SUCCESS;
    payload: OfferRequestData[];
}

export interface GetOfferRequestsErrorAction extends Action {
    type: typeof GET_OFFER_REQUESTS_ERROR;
    error: AjaxError;
}

export type CustomerOffersActionTypes =
    | GetCustomersAction
    | GetCustomersSuccessAction
    | GetCustomersErrorAction
    | SelectCustomerAction
    | GetOfferRequestsAction
    | GetOfferRequestsSuccessAction
    | GetOfferRequestsErrorAction;
