import React, { FC } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AnyAction, applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { OidcProvider, loadUser } from 'redux-oidc';

import userManager from './utils/userManager';
import rootReducer, { rootEpic, RootState } from './redux';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme';

import CustomerOffers from './views/customer-offers';
import MyOffers from './views/my-offers';
import Login from './views/login';
import OfferRequest from './views/offer-request';
import ThankYou from './views/thank-you';
import Assign from './views/assign';
import Settings from './views/settings';
import Statistics from './views/statistics';

import Navbar from './components/navbar';
import AuthWrapper from './components/auth-wrapper';
import Offer from './views/offer';

const App: FC = () => {
    // Setup Redux
    const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>();
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

    epicMiddleware.run(rootEpic);

    const init = () => {
        // Add init stuff as necessary
    };

    // Load potentially existing user data
    loadUser(store, userManager);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Provider store={store}>
                    <OidcProvider store={store} userManager={userManager}>
                        <>
                            <BrowserRouter>
                                <AuthWrapper onSuccess={init}>
                                    <>
                                        <Navbar />
                                        <Routes>
                                            <Route path='/kasittely' element={<MyOffers />} />
                                            <Route path='/kuormitus' element={<Assign />} />
                                            <Route path='/' element={<OfferRequest />} />
                                            <Route path='/tarjous' element={<Offer />} />
                                            <Route path='/tehdyt' element={<CustomerOffers />} />
                                            <Route path='/kiitos' element={<ThankYou />} />
                                            <Route path='/login' element={<Login />} />
                                            <Route path='/asetukset' element={<Settings />} />
                                            <Route path='/statistiikka' element={<Statistics />} />
                                        </Routes>
                                    </>
                                </AuthWrapper>
                            </BrowserRouter>
                        </>
                    </OidcProvider>
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
