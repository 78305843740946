import { Observable } from 'rxjs';

export const readFile = (file: File) =>
    new Observable<string>((subscriber) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            // Required to match types of reader.result and subscriber.next
            if (!reader.result) {
                throw Error('File empty!');
            }
            subscriber.next(reader.result.toString());
            subscriber.complete();
        };
        reader.onerror = () => subscriber.error(reader.error);
        return () => reader.abort(); // cancel function in case you unsubscribe from the obs
    });
