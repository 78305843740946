import { combineEpics, Epic } from 'redux-observable';
import { filter, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { api } from '../../utils/api';

import { getCustomersError, getCustomersSuccess, getOfferRequestsError, getOfferRequestsSuccess } from './actions';
import {
    Customer,
    CustomerOffersActionTypes,
    CustomerOffersState,
    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_OFFER_REQUESTS,
    GET_OFFER_REQUESTS_SUCCESS,
    SELECT_CUSTOMER,
} from './types';
import { RootState } from '..';

const initialState: CustomerOffersState = {
    customers: [],
    selectedCustomer: undefined,
    offerRequests: [],
};

const customerOffers = (state = initialState, action: CustomerOffersActionTypes) => {
    switch (action.type) {
        case GET_CUSTOMERS_SUCCESS:
            return { ...state, customers: action.payload };
        case SELECT_CUSTOMER:
            return { ...state, selectedCustomer: action.payload };
        case GET_OFFER_REQUESTS_SUCCESS:
            return { ...state, offerRequests: action.payload };
        default:
            return state;
    }
};

export const getCustomersEpic: Epic<CustomerOffersActionTypes, CustomerOffersActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(GET_CUSTOMERS)),
        mergeMap(() =>
            api.get<Customer[]>('/customer', { filter: { q: '' } }).pipe(
                map((res) => getCustomersSuccess(res)),
                catchError((error) => of(getCustomersError(error))),
            ),
        ),
    );

export const getOfferRequestsEpic: Epic<CustomerOffersActionTypes, CustomerOffersActionTypes, RootState> = (
    action$,
    state$,
) =>
    action$.pipe(
        filter(isOfType(GET_OFFER_REQUESTS)),
        mergeMap(() =>
            api
                .get<Customer[]>('/offerRequest', {
                    filter: {
                        status: ['SENT', 'ASSIGNED', 'UNASSIGNED', 'HANDLING', 'DONE'],
                        ...(state$.value.customerOffers.selectedCustomer
                            ? { customer: state$.value.customerOffers.selectedCustomer }
                            : {}),
                    },
                    sort: 'updated,DESC',
                })
                .pipe(
                    map((res) => getOfferRequestsSuccess(res)),
                    catchError((error) => of(getOfferRequestsError(error))),
                ),
        ),
    );

export const epics = combineEpics(getCustomersEpic, getOfferRequestsEpic);
export default customerOffers;
