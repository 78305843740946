import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Grid, Box, Button, Link, Menu, MenuItem, ListItemText, Icon } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import TawkTo from 'tawkto-react';

import userManager from '../utils/userManager';
import { RootState } from '../redux';

import logo from '../assets/images/logo.png';
import { Role } from '../redux/user/types';
import { Tawk } from '../types';

const PREFIX = 'Navbar';

const classes = {
    container: `${PREFIX}-container`,
    logo: `${PREFIX}-logo`,
    link: `${PREFIX}-link`,
    divisor: `${PREFIX}-divisor`,
    menuButton: `${PREFIX}-menuButton`,
    menu: `${PREFIX}-menu`,
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        backgroundColor: '#fff',
    },

    [`& .${classes.logo}`]: {
        height: '80px',
        padding: '0.6rem 0',
    },

    [`& .${classes.link}`]: {
        color: theme.palette.text.primary,
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        transition: `${theme.transitions.duration.shortest}ms`,
        '&:hover': {
            textDecoration: 'none',
            color: theme.palette.grey[600],
        },
    },

    [`& .${classes.divisor}`]: {
        marginLeft: '0.5rem',
        marginRight: '1rem',
    },

    [`& .${classes.menuButton}`]: {
        backgroundColor: theme.palette.grey[400],
    },

    [`& .${classes.menu}`]: {
        border: `3px solid ${theme.palette.grey[400]}`,
        transform: 'translate(0, -3px) !important',
    },
}));

const Navbar: FC = () => {
    const config = useSelector((state: RootState) => state.settings.simpleData);
    const user = useSelector((state: RootState) => state.oidc.user);
    const role = useSelector((state: RootState) => state.user.role);

    const [menuAnchor, setMenuAnchor] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    const [, setTawk] = useState<Tawk>();

    useEffect(() => {
        if (config === undefined) return;
        const regexpNames = /http(.*)\/chat\/(?<propertyId>\w+)\/(?<tawkId>\w+)/gm;
        const match = regexpNames.exec(config.tawkToLink || '');
        if (match?.groups?.propertyId && match?.groups?.tawkId) {
            const tawk: Tawk = new TawkTo(match.groups.propertyId, match.groups.tawkId);
            tawk.onLoad(() => {
                tawk.setAttributes({
                    name: `${user?.profile.company} - ${user?.profile.name}`,
                    email: user?.profile.email,
                    hash: config.tawkToHash,
                });
            });
            setTawk(tawk);
        }
    }, [config]);

    const handleSignout = () => {
        userManager.getUser().then((user) => {
            void userManager.removeUser();
            void userManager.signoutRedirect(
                user
                    ? {
                          id_token_hint: user?.id_token,
                      }
                    : {
                          extraQueryParams: {
                              client_id: window.config.clientId,
                          },
                      },
            );
        });
    };

    if (!user || user.expired) {
        return null;
    }

    return (
        <StyledBox boxShadow={2} className={classes.container}>
            <Container maxWidth='xl'>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item>
                        <img className={classes.logo} src={logo} alt='logo' />
                    </Grid>
                    <Grid item>
                        <a
                            href={window.config.customerPortalUrl}
                            className={classes.link}
                            style={{ textDecoration: 'none' }}>
                            Asiakasportaali
                        </a>
                        <Link component={RouterLink} to='/' className={classes.link}>
                            Tarjouspyyntö
                        </Link>
                        {(role === Role.ADMIN || role === Role.STAFF) && (
                            <Link component={RouterLink} to='/tarjous' className={classes.link}>
                                Tarjous
                            </Link>
                        )}
                        <Link component={RouterLink} to='/tehdyt' className={classes.link}>
                            Tehdyt
                        </Link>
                        {(role === Role.ADMIN || role === Role.STAFF) && (
                            <>
                                <Link component={RouterLink} to='/kasittely' className={classes.link}>
                                    Käsittely
                                </Link>
                                {role === Role.ADMIN && (
                                    <>
                                        <Link component={RouterLink} to='/kuormitus' className={classes.link}>
                                            Kuormitus
                                        </Link>
                                        <Link component={RouterLink} to='/asetukset' className={classes.link}>
                                            Asetukset
                                        </Link>
                                        <Link component={RouterLink} to='/statistiikka' className={classes.link}>
                                            Statistiikka
                                        </Link>
                                    </>
                                )}
                            </>
                        )}

                        <span className={classes.divisor}>|</span>
                        <Button
                            color='secondary'
                            className={menuAnchor ? classes.menuButton : undefined}
                            aria-controls='nav-menu'
                            aria-haspopup='true'
                            onClick={(e) => setMenuAnchor(e.currentTarget)}>
                            {user.profile.company ? `${user.profile.company} - ` : ''}
                            {user.profile.name}
                            <Icon>arrow_drop_down</Icon>
                        </Button>
                        <Menu
                            id='nav-menu'
                            classes={{ paper: classes.menu }}
                            anchorEl={menuAnchor}
                            elevation={0}
                            keepMounted
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(menuAnchor)}
                            onClose={() => setMenuAnchor(null)}>
                            <MenuItem onClick={handleSignout}>
                                <ListItemText>Kirjaudu ulos</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Container>
        </StyledBox>
    );
};

export default Navbar;
