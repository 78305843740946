import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { parse as parseCR } from 'content-range';

import {
    GET_UNASSIGNED_OFFERS,
    GET_UNASSIGNED_OFFERS_SUCCESS,
    GET_UNASSIGNED_OFFERS_ERROR,
    GetUnassignedOffersAction,
    GetUnassignedOffersSuccessAction,
    GetUnassignedOffersErrorAction,
    CHANGE_OFFERS_PAGE,
    changeOffersPageAction,
    GET_ASSIGNED_OFFERS,
    GET_ASSIGNED_OFFERS_SUCCESS,
    GET_ASSIGNED_OFFERS_ERROR,
    GetAssignedOffersAction,
    GetAssignedOffersSuccessAction,
    GetAssignedOffersErrorAction,
    GET_STAFF,
    GET_STAFF_SUCCESS,
    GET_STAFF_ERROR,
    GetStaffAction,
    GetStaffSuccessAction,
    GetStaffErrorAction,
    ASSIGN_OFFER,
    ASSIGN_OFFER_SUCCESS,
    ASSIGN_OFFER_ERROR,
    AssignOfferAction,
    AssignOfferSuccessAction,
    AssignOfferErrorAction,
    User,
} from './types';
import { OfferRequestData } from '../offerRequest/types';

export const getUnassignedOffers = (): GetUnassignedOffersAction => ({
    type: GET_UNASSIGNED_OFFERS,
});

export const getUnassignedOffersSuccess = (
    res: AjaxResponse<OfferRequestData[]>,
): GetUnassignedOffersSuccessAction => ({
    type: GET_UNASSIGNED_OFFERS_SUCCESS,
    payload: {
        offers: res.response,
        size: parseCR(res.xhr.getResponseHeader('content-range') || '')?.size || 0,
    },
});

export const getUnassignedOffersError = (error: AjaxError): GetUnassignedOffersErrorAction => ({
    type: GET_UNASSIGNED_OFFERS_ERROR,
    error,
});

export const changeOffersPage = (page: number): changeOffersPageAction => ({
    type: CHANGE_OFFERS_PAGE,
    payload: {
        page,
    },
});

export const getAssignedOffers = (id: string, page: number): GetAssignedOffersAction => ({
    type: GET_ASSIGNED_OFFERS,
    payload: {
        person: id,
        page,
    },
});

export const getAssignedOffersSuccess = (
    res: AjaxResponse<OfferRequestData[]>,
    person: string,
    page: number,
): GetAssignedOffersSuccessAction => ({
    type: GET_ASSIGNED_OFFERS_SUCCESS,
    payload: {
        offers: res.response,
        person,
        page,
        size: parseCR(res.xhr.getResponseHeader('content-range') || '')?.size || 0,
    },
});

export const getAssignedOffersError = (error: AjaxError): GetAssignedOffersErrorAction => ({
    type: GET_ASSIGNED_OFFERS_ERROR,
    error,
});

export const getStaff = (): GetStaffAction => ({
    type: GET_STAFF,
});

export const getStaffSuccess = (res: AjaxResponse<User[]>): GetStaffSuccessAction => ({
    type: GET_STAFF_SUCCESS,
    payload: {
        staff: res.response,
    },
});

export const getStaffError = (error: AjaxError): GetStaffErrorAction => ({
    type: GET_STAFF_ERROR,
    error,
});

export const assignOffer = (offerId: string, staffId: string): AssignOfferAction => ({
    type: ASSIGN_OFFER,
    payload: {
        offerId,
        staffId,
    },
});

export const assignOfferSuccess = (): AssignOfferSuccessAction => ({
    type: ASSIGN_OFFER_SUCCESS,
});

export const assignOfferError = (error: AjaxError): AssignOfferErrorAction => ({
    type: ASSIGN_OFFER_ERROR,
    error,
});
