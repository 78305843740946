import { Action, EmptyObject } from 'redux';
import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { OfferData } from '../offer/types';

// Other
export interface Customer {
    id: string;
    name: string;
    user: string;
    customUser: boolean;
}

// State
export interface OfferRequestState {
    id: null | string;
    savedOrders: null | OfferRequestData[];
    fileIdReplace?: { tmpId: string; id: string };
    customers: Customer[];
    newCustomerId?: string;
}

// Action types
export const MAKE_NEW_OFFER_REQUEST = 'offer-request:MAKE_NEW_OFFER_REQUEST';
export const MAKE_NEW_OFFER_REQUEST_SUCCESS = 'offer-request:MAKE_NEW_OFFER_REQUEST_SUCCESS';
export const MAKE_NEW_OFFER_REQUEST_ERROR = 'offer-request:MAKE_NEW_OFFER_REQUEST_ERROR';
export const USE_SAVED_ORDER_ID = 'offer-request:USE_SAVED_ORDER_ID';
export const GET_SAVED_ORDERS = 'offer-request:GET_SAVED_ORDERS';
export const GET_SAVED_ORDERS_SUCCESS = 'offer-request:GET_SAVED_ORDERS_SUCCESS';
export const GET_SAVED_ORDERS_ERROR = 'offer-request:GET_SAVED_ORDERS_ERROR';
export const AUTO_SAVE = 'offer-request:AUTO_SAVE';
export const AUTO_SAVE_SUCCESS = 'offer-request:AUTO_SAVE_SUCCESS';
export const AUTO_SAVE_ERROR = 'offer-request:AUTO_SAVE_ERROR';
export const SAVE_FILE = 'offer-request:SAVE_FILE';
export const SAVE_FILE_SUCCESS = 'offer-request:SAVE_FILE_SUCCESS';
export const SAVE_FILE_ERROR = 'offer-request:SAVE_FILE_ERROR';
export const REMOVE_FILE = 'offer-request:REMOVE_FILE';
export const REMOVE_FILE_SUCCESS = 'offer-request:REMOVE_FILE_SUCCESS';
export const REMOVE_FILE_ERROR = 'offer-request:REMOVE_FILE_ERROR';
export const SUBMIT_OFFER_REQUEST = 'offer-request:SUBMIT_OFFER';
export const SUBMIT_OFFER_REQUEST_SUCCESS = 'offer-request:SUBMIT_OFFER_SUCCESS';
export const SUBMIT_OFFER_REQUEST_ERROR = 'offer-request:SUBMIT_OFFER_ERROR';
export const GET_CUSTOMERS = 'offer-request:GET_CUSTOMERS';
export const GET_CUSTOMERS_SUCCESS = 'offer-request:GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'offer-request:GET_CUSTOMERS_ERROR';
export const DOWNLOAD_OFFER_REQUEST_FILE = 'my-offers:DOWNLOAD_OFFER_REQUEST_FILE';
export const DOWNLOAD_OFFER_FILE_REQUEST_SUCCESS = 'my-offers:DOWNLOAD_OFFER_REQUEST_FILE_SUCCESS';
export const DOWNLOAD_OFFER_REQUEST_FILE_ERROR = 'my-offers:DOWNLOAD_OFFER_REQUEST_FILE_ERROR';

// Payloads
export interface OGCellType {
    [tmpId: string]: string | null;
}

export interface OGRowType {
    cells: OGCellType[];
}

export interface OGColumnDef {
    field: string;
    checkboxSelection?: boolean;
    suppressSizeToFit?: boolean;
    width?: number;
    flex?: number;
    editable?: boolean;
    cellEditor?: string;
    cellEditorParams?: Record<string, number>;
}

export interface IFile {
    id: string;
    file?: File;
    name: string;
    active: boolean;
}

export interface OfferTable {
    header: {
        cells: OGColumnDef[];
    };
    rows: OGRowType[];
}

export interface OfferRequestData {
    id?: string;
    number?: number;
    customer?: {
        id?: string;
        name?: string;
        user?: string;
        customUser?: boolean;
    };
    reference?: string;
    message?: string;
    fast?: boolean;
    deadlineWish?: string;
    offerTable?: OfferTable;
    files?: IFile[];
    created?: string;
    updated?: string;
    submissionTime?: string;
    completionTime?: string;
    status?: string;
    assignments?: {
        offerRequest: string;
        user: string;
        status: string;
        created: string;
        updated?: string;
        reason?: string;
        affectTime: string;
    }[];
    original?: boolean;
    offer?: Omit<OfferData, 'offerRequest'> | null;
}

// Actions
export interface MakeNewOfferRequestAction extends Action {
    type: typeof MAKE_NEW_OFFER_REQUEST;
}

export interface MakeNewOfferRequestSuccessAction extends Action {
    type: typeof MAKE_NEW_OFFER_REQUEST_SUCCESS;
    payload: {
        id: string;
    };
}

export interface MakeNewOfferRequestErrorAction extends Action {
    type: typeof MAKE_NEW_OFFER_REQUEST_ERROR;
    error: AjaxError;
}

export interface GetSavedOrderAction extends Action {
    type: typeof GET_SAVED_ORDERS;
}

export interface GetSavedOrderSuccessAction extends Action {
    type: typeof GET_SAVED_ORDERS_SUCCESS;
    payload: {
        savedOrders: OfferRequestData[];
    };
}

export interface GetSavedOrderErrorAction extends Action {
    type: typeof GET_SAVED_ORDERS_ERROR;
    error: AjaxError;
}

export interface UseSavedOrderIdAction extends Action {
    type: typeof USE_SAVED_ORDER_ID;
    payload: {
        id: string;
    };
}

export interface AutoSaveAction extends Action {
    type: typeof AUTO_SAVE;
    payload: {
        data: OfferRequestData;
    };
}

export interface AutoSaveSuccessAction extends Action {
    type: typeof AUTO_SAVE_SUCCESS;
    payload: {
        newCustomerId: string;
    };
}

export interface AutoSaveErrorAction extends Action {
    type: typeof AUTO_SAVE_ERROR;
    error: AjaxError;
}

export interface SaveFileAction extends Action {
    type: typeof SAVE_FILE;
    payload: {
        file: File;
        tmpId: string;
        name: string;
    };
}

export interface SaveFileSuccessAction extends Action {
    type: typeof SAVE_FILE_SUCCESS;
    payload: {
        tmpId: string;
        id: string;
    };
}

export interface SaveFileErrorAction extends Action {
    type: typeof SAVE_FILE_ERROR;
    error: AjaxError;
}

export interface RemoveFileAction extends Action {
    type: typeof REMOVE_FILE;
    payload: {
        id: string;
    };
}

export interface RemoveFileSuccessAction extends Action {
    type: typeof REMOVE_FILE_SUCCESS;
}

export interface RemoveFileErrorAction extends Action {
    type: typeof REMOVE_FILE_ERROR;
    error: AjaxError;
}

export interface SubmitOfferRequestAction extends Action {
    type: typeof SUBMIT_OFFER_REQUEST;
}

export interface SubmitOfferRequestSuccessAction extends Action {
    type: typeof SUBMIT_OFFER_REQUEST_SUCCESS;
    payload: AjaxResponse<EmptyObject>;
}

export interface SubmitOfferRequestErrorAction extends Action {
    type: typeof SUBMIT_OFFER_REQUEST_ERROR;
    error: AjaxError;
}

export interface GetCustomersAction extends Action {
    type: typeof GET_CUSTOMERS;
}

export interface GetCustomersSuccessAction extends Action {
    type: typeof GET_CUSTOMERS_SUCCESS;
    payload: {
        customers: Customer[];
    };
}

export interface GetCustomersErrorAction extends Action {
    type: typeof GET_CUSTOMERS_ERROR;
    error: AjaxError;
}

export interface DownloadOfferRequestFileAction extends Action {
    type: typeof DOWNLOAD_OFFER_REQUEST_FILE;
    payload: {
        offerId: string;
        fileId: string;
        name: string;
    };
}

export interface DownloadOfferRequestFileSuccessAction extends Action {
    type: typeof DOWNLOAD_OFFER_FILE_REQUEST_SUCCESS;
}

export interface DownloadOfferRequestFileErrorAction extends Action {
    type: typeof DOWNLOAD_OFFER_REQUEST_FILE_ERROR;
    error: AjaxError;
}

export type OfferRequestActionTypes =
    | MakeNewOfferRequestAction
    | MakeNewOfferRequestSuccessAction
    | MakeNewOfferRequestErrorAction
    | GetSavedOrderAction
    | GetSavedOrderErrorAction
    | GetSavedOrderSuccessAction
    | UseSavedOrderIdAction
    | AutoSaveAction
    | AutoSaveSuccessAction
    | AutoSaveErrorAction
    | SaveFileAction
    | SaveFileSuccessAction
    | SaveFileErrorAction
    | RemoveFileAction
    | RemoveFileSuccessAction
    | RemoveFileErrorAction
    | SubmitOfferRequestAction
    | SubmitOfferRequestSuccessAction
    | SubmitOfferRequestErrorAction
    | GetCustomersAction
    | GetCustomersSuccessAction
    | GetCustomersErrorAction
    | DownloadOfferRequestFileAction
    | DownloadOfferRequestFileSuccessAction
    | DownloadOfferRequestFileErrorAction;
