const getUtcOffset = (timezone: string) => {
    const date = new Date();
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const localDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
    return (localDate.getTime() - utcDate.getTime()) / 6e4; // minutes
};

const removeTimezoneInfo = (time: string, timezone = 'Europe/Helsinki'): string => {
    if (time === null) return '';

    const date = new Date(new Date().toISOString().split('T')[0] + 'T' + time);
    return date.toLocaleString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', timeZone: timezone });
};

const addTimezoneInfo = (time: string, timezone = 'Europe/Helsinki'): string => {
    const date = new Date();
    date.setHours(parseInt(time.split(':')[0]));
    date.setMinutes(parseInt(time.split(':')[1]));
    const tzo = getUtcOffset(timezone);
    const dif = tzo >= 0 ? '+' : '-';

    return (
        date.getHours().toString().padStart(2, '0') +
        ':' +
        date.getMinutes().toString().padStart(2, '0') +
        dif +
        Math.floor(Math.abs(tzo) / 60)
            .toString()
            .padStart(2, '0') +
        ':' +
        (Math.abs(tzo) % 60).toString().padStart(2, '0')
    );
};

export { addTimezoneInfo, removeTimezoneInfo };
