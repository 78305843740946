import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';
import { IFile, OfferRequestData, OfferTable } from '../offerRequest/types';

export interface OfferData {
    created?: string;
    creator?: string;
    deliveryCondition?: string;
    deliveryMethod?: string;
    deliveryItem?: string;
    deliveryTime?: string;
    id?: string;
    message?: string;
    offerTable?: OfferTable;
    paymentTerms?: string;
    status?: string;
    submissionTime?: string;
    updated?: string;
    validUntil?: string;
    offerRequest?: Omit<OfferRequestData, 'offer'> | null;
    files?: IFile[];
}

// State
export interface OfferState {
    withoutOfferRequest: boolean;
    id?: string;
    fileIdReplace?: { tmpId: string; id: string };
    offerData?: OfferData;
    offerRequestData?: OfferRequestData;
    newCustomerId?: string;
}

// Action types
export const RESET_OFFER_PAGE = 'offer:RESET_OFFER_PAGE';
export const GET_OFFER_REQUEST = 'offer:GET_OFFER_REQUEST';
export const GET_OFFER_REQUEST_SUCCESS = 'offer:GET_OFFER_REQUEST_SUCCESS';
export const GET_OFFER_REQUEST_ERROR = 'offer:GET_OFFER_REQUEST_ERROR';
export const CREATE_OFFER = 'offer:CREATE_OFFER';
export const CREATE_OFFER_SUCCESS = 'offer:CREATE_OFFER_SUCCESS';
export const CREATE_OFFER_ERROR = 'offer:CREATE_OFFER_ERROR';
export const AUTO_SAVE = 'offer:AUTO_SAVE';
export const AUTO_SAVE_SUCCESS = 'offer:AUTO_SAVE_SUCCESS';
export const AUTO_SAVE_ERROR = 'offer:AUTO_SAVE_ERROR';
export const SAVE_FILE = 'offer:SAVE_FILE';
export const SAVE_FILE_SUCCESS = 'offer:SAVE_FILE_SUCCESS';
export const SAVE_FILE_ERROR = 'offer:SAVE_FILE_ERROR';
export const REMOVE_FILE = 'offer:REMOVE_FILE';
export const REMOVE_FILE_SUCCESS = 'offer:REMOVE_FILE_SUCCESS';
export const REMOVE_FILE_ERROR = 'offer:REMOVE_FILE_ERROR';
export const SUBMIT_OFFER = 'offer:SUBMIT_OFFER';
export const SUBMIT_OFFER_SUCCESS = 'offer:SUBMIT_OFFER_SUCCESS';
export const SUBMIT_OFFER_ERROR = 'offer:SUBMIT_OFFER_ERROR';

// Actions
export interface ResetOfferPageAction extends Action {
    type: typeof RESET_OFFER_PAGE;
}

export interface GetOfferRequestAction extends Action {
    type: typeof GET_OFFER_REQUEST;
    payload: {
        id: string;
    };
}

export interface GetOfferRequestSuccessAction extends Action {
    type: typeof GET_OFFER_REQUEST_SUCCESS;
    payload: OfferRequestData;
}

export interface GetOfferRequestErrorAction extends Action {
    type: typeof GET_OFFER_REQUEST_ERROR;
    error: AjaxError;
}

export interface CreateOfferAction extends Action {
    type: typeof CREATE_OFFER;
    payload: OfferData;
}

export interface CreateOfferSuccessAction extends Action {
    type: typeof CREATE_OFFER_SUCCESS;
    payload: OfferData;
}

export interface CreateOfferErrorAction extends Action {
    type: typeof CREATE_OFFER_ERROR;
    error: AjaxError;
}

export interface AutoSaveAction extends Action {
    type: typeof AUTO_SAVE;
    payload: OfferData;
}

export interface AutoSaveSuccessAction extends Action {
    type: typeof AUTO_SAVE_SUCCESS;
}

export interface AutoSaveErrorAction extends Action {
    type: typeof AUTO_SAVE_ERROR;
    error: AjaxError;
}

export interface SaveFileAction extends Action {
    type: typeof SAVE_FILE;
    payload: {
        file: File;
        tmpId: string;
        name: string;
    };
}

export interface SaveFileSuccessAction extends Action {
    type: typeof SAVE_FILE_SUCCESS;
    payload: {
        tmpId: string;
        id: string;
    };
}

export interface SaveFileErrorAction extends Action {
    type: typeof SAVE_FILE_ERROR;
    error: AjaxError;
}

export interface RemoveFileAction extends Action {
    type: typeof REMOVE_FILE;
    payload: {
        id: string;
    };
}

export interface RemoveFileSuccessAction extends Action {
    type: typeof REMOVE_FILE_SUCCESS;
}

export interface RemoveFileErrorAction extends Action {
    type: typeof REMOVE_FILE_ERROR;
    error: AjaxError;
}

export interface SubmitOfferAction extends Action {
    type: typeof SUBMIT_OFFER;
}

export interface SubmitOfferSuccessAction extends Action {
    type: typeof SUBMIT_OFFER_SUCCESS;
}

export interface SubmitOfferErrorAction extends Action {
    type: typeof SUBMIT_OFFER_ERROR;
    error: AjaxError;
}

export type OfferActionTypes =
    | ResetOfferPageAction
    | GetOfferRequestAction
    | GetOfferRequestSuccessAction
    | GetOfferRequestErrorAction
    | CreateOfferAction
    | CreateOfferSuccessAction
    | CreateOfferErrorAction
    | AutoSaveAction
    | AutoSaveSuccessAction
    | AutoSaveErrorAction
    | SaveFileAction
    | SaveFileSuccessAction
    | SaveFileErrorAction
    | RemoveFileAction
    | RemoveFileSuccessAction
    | RemoveFileErrorAction
    | SubmitOfferAction
    | SubmitOfferSuccessAction
    | SubmitOfferErrorAction;
