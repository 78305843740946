import React, { FC, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import OfferGrid from '../../components/offer-grid';

import { getConfig } from '../../redux/settings/actions';

import { IFile, OGCellType, OGColumnDef } from '../../redux/offerRequest/types';
import { RootState } from '../../redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    autoSave,
    createOffer,
    getOfferRequest,
    removeFile,
    resetOfferPage,
    saveFile,
    submitOffer,
} from '../../redux/offer/actions';
import { downloadOfferRequestFile, getCustomers } from '../../redux/offerRequest/actions';
import { OfferData } from '../../redux/offer/types';

const PREFIX = 'OfferRequest';

const classes = {
    inputLong: `${PREFIX}-inputLong`,
    select: `${PREFIX}-select`,
    offerText: `${PREFIX}-offerText`,
    dl_container: `${PREFIX}-dl_container`,
    textField: `${PREFIX}-textField`,
    modal: `${PREFIX}-modal`,
    modalTitle: `${PREFIX}-modalTitle`,
    fileList: `${PREFIX}-fileList`,
    fileListText: `${PREFIX}-fileListText`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
    [`& .${classes.inputLong}`]: {
        width: 350,
    },

    [`& .${classes.select}`]: {
        width: 350,
    },

    [`& .${classes.offerText}`]: {
        maxWidth: '100%',
        width: '800px',
        margin: 'auto',
        fontSize: theme.typography.fontSize,
    },

    [`& .${classes.dl_container}`]: {
        display: 'inline-block',
    },

    [`& .${classes.textField}`]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },

    [`& .MuiInputLabel-root.Mui-disabled`]: {
        color: theme.palette.text.secondary,
    },

    [`& .Mui-disabled .MuiInputBase-input:hover`]: {
        borderColor: '#a9a9a9',
    },

    [`& .Mui-disabled.MuiOutlinedInput-root fieldset`]: {
        borderColor: '#a9a9a9',
    },

    [`& .Mui-disabled.MuiOutlinedInput-root:hover fieldset`]: {
        borderColor: '#a9a9a9',
    },

    [`& .${classes.fileList}`]: {
        minWidth: '300px',
        display: 'inline-block',
    },

    [`& .${classes.fileListText}`]: {
        paddingRight: theme.spacing(2),
    },
}));

interface FormSimpleData {
    customerId: string;
    reference: string;
    customerMessage: string;
    message: string;
    gridHeader: OGColumnDef[];
    gridData: OGCellType[];
    validUntil: string;
    deliveryTime: string;
    deliveryCondition: string;
    deliveryMethod: string;
    paymentTerms: string;
}

const Offer: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [fromScratchState, setFromScratchState] = useState<{ fromScratch: boolean; created: boolean }>({
        fromScratch: false,
        created: false,
    });

    // ON MOUNT
    useEffect(() => {
        dispatch(getConfig());
        dispatch(getCustomers());

        document.title = 'Tarjouspyyntöportaali';

        const offerRequestId = searchParams.get('offerRequestId');
        if (offerRequestId !== null) {
            dispatch(getOfferRequest(offerRequestId));
        } else {
            setFromScratchState({ ...fromScratchState, fromScratch: true });
        }

        // Init an epmty grid
        const emptyGridData: (string | null)[][] = [...Array(4)].map((e) => Array(7).fill(null));
        setGridData(emptyGridData);

        return () => {
            dispatch(resetOfferPage());
        };
    }, []);

    // Offer and offer request data
    const { id, fileIdReplace, offerData, withoutOfferRequest } = useSelector((state: RootState) => state.offer);
    const { customers } = useSelector((state: RootState) => state.offerRequest); // FIXME
    const { simpleData } = useSelector((state: RootState) => state.settings); // FIXME

    const emptyFormSimpleData = {
        customerId: '',
        customerMessage: '',
        reference: '',
        message: '',
        gridData: [],
        gridHeader: [],
        validUntil: '',
        deliveryTime: '',
        deliveryCondition: '',
        deliveryMethod: '',
        paymentTerms: '',
    };
    const [formSimpleData, setFormSimpleData] = useState<FormSimpleData>(emptyFormSimpleData);
    const [gridData, setGridData] = useState<(string | null)[][] | undefined>(undefined);

    // This is required for timeouts to use the newest version of formSimpleData
    const formSimpleDataRef = useRef(formSimpleData);
    formSimpleDataRef.current = formSimpleData;

    useEffect(() => {
        if (fromScratchState.fromScratch && !fromScratchState.created && formSimpleData.customerId !== '') {
            dispatch(
                createOffer({
                    offerRequest: {},
                }),
            );
            setFromScratchState({ ...fromScratchState, created: true });
        }
    }, [formSimpleData]);

    // File state
    const [formFiles, setFormFiles] = useState<IFile[]>([]);

    // Replace file tmpIds with the ids from the API
    useEffect(() => {
        if (fileIdReplace) {
            for (let i = 0; i < formFiles.length; i++) {
                if (formFiles[i].id === fileIdReplace.tmpId) {
                    formFiles[i].id = fileIdReplace.id;
                }
            }
            setFormFiles(formFiles);
        }
        // fileIdReplace is the important one here
    }, [fileIdReplace, formFiles, dispatch]);

    // Create offer if previously existing offerRequest has been fetched but offer hasn't yet been created
    useEffect(() => {
        if (offerData?.offerRequest && offerData.id) return;

        // Fill offerRequest form entries
        setFormSimpleData({
            ...formSimpleData,
            customerId: offerData?.offerRequest?.customer?.id || '',
            reference: offerData?.offerRequest?.reference || '',
            customerMessage: offerData?.offerRequest?.message || '',
        });

        // Create new offer, but make sure not to create one without offerRequest id
        if (offerData?.offerRequest?.id) {
            dispatch(
                createOffer({
                    offerRequest: {
                        id: offerData?.offerRequest?.id,
                    },
                    offerTable: offerData?.offerRequest?.offerTable,
                }),
            );
        }
    }, [offerData?.offerRequest]);

    useEffect(() => {
        if (!offerData) return;

        const loadedData: (string | null)[][] = [];
        if (offerData.offerTable) {
            for (let i = 0; i < offerData.offerTable.rows.length; i++) {
                const row: (string | null)[] = [];
                for (let j = 0; j < offerData.offerTable.rows[0].cells.length; j++) {
                    row.push(offerData.offerTable.rows[i].cells[j].content);
                }
                loadedData.push(row);
            }
            if (loadedData.length > 0) {
                setGridData(loadedData);
            }
        }
    }, [offerData]);

    // Confirmation modal
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // Autosave timeout
    const [blurTimeout, setBlurTimeout] = useState<NodeJS.Timeout | null>(null);

    // HANDLERS AND HELPERS //

    // Form change helpers
    const autoSaveTimeout = () => {
        if (blurTimeout) {
            clearTimeout(blurTimeout);
        }
        const timeout = setTimeout(() => {
            autoSaveForm(formSimpleDataRef.current);
            setBlurTimeout(null);
        }, 2000);
        setBlurTimeout(timeout);
    };

    const handleFormSimpleDataChange = (
        name: string,
        event: React.ChangeEvent<
            HTMLInputElement | { name?: string | undefined; value: unknown; type?: string; checked?: boolean }
        >,
    ) => {
        // Update state
        if (event.target.type === 'checkbox') {
            setFormSimpleData({ ...formSimpleData, [name]: event.target.checked });
        } else {
            setFormSimpleData({ ...formSimpleData, [name]: event.target.value });
        }

        // Handle autosave
        autoSaveTimeout();
    };

    const handleFormSimpleDataSelectChange = (name: string, event: SelectChangeEvent) => {
        // Update state
        setFormSimpleData({ ...formSimpleData, [name]: event.target.value });

        // Handle autosave
        autoSaveTimeout();
    };

    const handleGetGridData = (gridData: OGCellType[], gridHeader: OGColumnDef[]) => {
        setFormSimpleData({
            ...formSimpleData,
            gridData,
            gridHeader,
        });

        // Handle autosave
        autoSaveTimeout();
    };

    // File upload helper
    const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        const newFiles: IFile[] = [];
        if (files) {
            for (let i = 0; i < files.length; i++) {
                const newFile = files.item(i);
                if (newFile) {
                    // Use temporary id until api returns a real one
                    const tmpId = `${Date.now() + i}`;
                    newFiles.push({ id: tmpId, file: newFile, name: newFile.name, active: true });
                    dispatch(saveFile(tmpId, newFile, newFile.name));
                }
            }
        }
        setFormFiles([...formFiles, ...newFiles]);
    };

    // File delete helper
    const handleRemoveFile = (id: string) => {
        for (let i = 0; i < formFiles.length; i++) {
            const f2 = formFiles[i];

            if (id === f2.id) {
                // Remove from state
                setFormFiles([...formFiles.slice(0, i), ...formFiles.slice(i + 1, formFiles.length)]);
                // Remove from server
                dispatch(removeFile(id));
            }
        }
    };

    const assembleOffer = (fsd: FormSimpleData) => {
        const {
            customerId,
            reference,
            customerMessage,
            message,
            gridHeader,
            gridData,
            validUntil,
            deliveryTime,
            deliveryCondition,
            deliveryMethod,
            paymentTerms,
        } = fsd;

        if (!offerData?.offerRequest) return;

        const rows = [];
        const columns = [];
        for (let i = 0; i < gridHeader.length; i++) {
            columns.push(gridHeader[i].field);
        }
        for (let i = 0; i < gridData.length; i++) {
            // Check if row empty
            let empty = true;
            for (let j = 0; j < columns.length; j++) {
                if (gridData[i][columns[j]]) {
                    empty = false;
                }
            }
            if (!empty) {
                const row: { cells: { content: string | null }[] } = { cells: [] };
                for (let j = 1; j < columns.length; j++) {
                    row.cells.push({ content: gridData[i][columns[j]] });
                }
                rows.push(row);
            }
        }

        const data: OfferData = {
            offerRequest: offerData?.offerRequest,
            message,
            validUntil: validUntil ? new Date(`${validUntil} 23:59:59`).toISOString() : undefined,
            deliveryTime,
            deliveryCondition,
            deliveryMethod,
            paymentTerms,
        };

        if (withoutOfferRequest) {
            data.offerRequest = {
                ...data.offerRequest,
                customer: { id: customerId },
                reference,
                message: customerMessage,
            };
        }

        const columnNames = gridHeader.map((h) => ({ field: h.field })).slice(1);

        // Only add offer table if not empty
        if (rows.length > 0) {
            data.offerTable = {
                header: {
                    cells: columnNames,
                },
                rows,
            };
        }

        return data;
    };

    // Form autosave helpers
    const autoSaveForm = (fsd: FormSimpleData) => {
        // Create offer table
        const data = assembleOffer(fsd);

        if (data) dispatch(autoSave(data));
    };

    const handleShowSubmitConfirmationModal = () => {
        setShowConfirmationModal(true);
        if (blurTimeout) {
            clearTimeout(blurTimeout);
        }
        autoSaveForm(formSimpleDataRef.current);
    };

    // TODO: handle error
    // Submission helper
    const handleOfferSubmission = () => {
        if (blurTimeout) {
            clearTimeout(blurTimeout);
            autoSaveForm(formSimpleDataRef.current);
        }

        dispatch(submitOffer());

        // Clear everything
        setFormSimpleData(emptyFormSimpleData);
        setGridData([]);

        navigate('/kasittely');
    };

    const submitDisabledText = (fsd: FormSimpleData) => {
        if (
            !fsd.validUntil ||
            !fsd.deliveryCondition ||
            !fsd.deliveryMethod ||
            !fsd.deliveryTime ||
            !fsd.paymentTerms
        ) {
            return 'Täytä kaikki kentät!';
        } else {
            return false;
        }
    };

    return id || fromScratchState.fromScratch ? (
        <StyledContainer maxWidth='xl'>
            <Box my={2}>
                <Box my={2}>
                    <Typography variant='h1' component='h1'>
                        Tarjous
                    </Typography>
                    <Grid container direction='column' spacing={2}>
                        <Grid item container direction='row' spacing={2}>
                            <Grid item container direction='column' xl={8} spacing={2}>
                                {customers.length > 0 && (
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel id='customer-label' shrink={true}>
                                                Asiakas
                                            </InputLabel>
                                            <Select
                                                id='customer'
                                                labelId='customer-label'
                                                className={classes.select}
                                                value={formSimpleData.customerId}
                                                disabled={!withoutOfferRequest}
                                                onChange={(e) => handleFormSimpleDataSelectChange('customerId', e)}>
                                                {customers.map((c) => (
                                                    <MenuItem key={c.id} value={c.id}>
                                                        {c.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item>
                                    <TextField
                                        id='reference'
                                        className={classes.inputLong}
                                        label='Viite/Merkki'
                                        InputLabelProps={{ shrink: true }}
                                        value={formSimpleData.reference}
                                        disabled={!withoutOfferRequest}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleFormSimpleDataChange('reference', e)
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id='offer-message'
                                        className={classes.offerText}
                                        multiline
                                        rows={5}
                                        label={'Asiakkaan viesti'}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder='Voit kuvailla tähän vapaasti tarpeen ja/tai täyttää mittoja ja tietoja alla olevaan taulukkoon'
                                        variant='outlined'
                                        value={formSimpleData.customerMessage}
                                        disabled={!withoutOfferRequest}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleFormSimpleDataChange('customerMessage', e)
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xl={4}>
                                <Grid item>
                                    <Typography variant='h4'>Liitteet:</Typography>
                                    {offerData?.offerRequest?.files && offerData.offerRequest.files.length > 0 ? (
                                        <List>
                                            {offerData.offerRequest.files.map((f, i) => (
                                                <ListItem key={f.id}>
                                                    <ListItemText className={classes.fileListText}>
                                                        {' '}
                                                        <a
                                                            onClick={() =>
                                                                dispatch(
                                                                    downloadOfferRequestFile(
                                                                        offerData?.offerRequest?.id || '',
                                                                        f.id,
                                                                        f.name,
                                                                    ),
                                                                )
                                                            }
                                                            href='javaScript:void(0)'>
                                                            {f.name}
                                                        </a>
                                                    </ListItemText>
                                                </ListItem>
                                            ))}
                                        </List>
                                    ) : (
                                        'Ei liitteitä'
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {gridData && (
                                <OfferGrid
                                    callbackGetGridData={handleGetGridData}
                                    loadedData={gridData}
                                    includePrice={true}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            <TextField
                                id='offer-message'
                                className={classes.offerText}
                                multiline
                                rows={5}
                                label='Viesti'
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                value={formSimpleData.message}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleFormSimpleDataChange('message', e)
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography component='p' gutterBottom>
                                Lisää tästä tilaukseen liittyviä tiedostoja (excel, kuvia, yms.)
                            </Typography>
                            <Button
                                variant='contained'
                                color='secondary'
                                component='label'
                                startIcon={<Icon>attach_file</Icon>}>
                                Lisää liite
                                <input type='file' hidden onChange={handleAddFiles} />
                            </Button>
                            <div /> {/* To clear file list from button */}
                            <List className={classes.fileList}>
                                {formFiles.map((f) => (
                                    <ListItem key={f.id}>
                                        <ListItemText className={classes.fileListText}>{f.name}</ListItemText>
                                        <ListItemSecondaryAction>
                                            <IconButton onClick={() => handleRemoveFile(f.id)} size='large'>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item>
                            <Grid container direction='column' spacing={2}>
                                <Grid item>
                                    <TextField
                                        id='offer-dl'
                                        label='Tarjouksen voimassaoloaika'
                                        type='date'
                                        required={true}
                                        InputLabelProps={{ shrink: true }}
                                        value={formSimpleData.validUntil}
                                        onChange={(e) => handleFormSimpleDataChange('validUntil', e)}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel id='delivery-time-label' shrink={true} required={true}>
                                            Toimitusaika
                                        </InputLabel>
                                        <Select
                                            id='delivery-time'
                                            labelId='delivery-time-label'
                                            className={classes.select}
                                            value={formSimpleData.deliveryTime}
                                            onChange={(e) => handleFormSimpleDataSelectChange('deliveryTime', e)}>
                                            {simpleData?.deliveryTimeOptions?.map((o) => (
                                                <MenuItem key={o.id} value={o.option}>
                                                    {o.option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel id='delivery-time-label' shrink={true} required={true}>
                                            Toimitusehto
                                        </InputLabel>
                                        <Select
                                            id='delivery-condition'
                                            labelId='delivery-condition-label'
                                            className={classes.select}
                                            value={formSimpleData.deliveryCondition}
                                            onChange={(e) => handleFormSimpleDataSelectChange('deliveryCondition', e)}>
                                            {simpleData?.deliveryConditionOptions?.map((o) => (
                                                <MenuItem key={o.id} value={o.option}>
                                                    {o.option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel id='delivery-method-label' shrink={true} required={true}>
                                            Toimitustapa
                                        </InputLabel>
                                        <Select
                                            id='delivery-method'
                                            labelId='delivery-method-label'
                                            className={classes.select}
                                            value={formSimpleData.deliveryMethod}
                                            onChange={(e) => handleFormSimpleDataSelectChange('deliveryMethod', e)}>
                                            {simpleData?.deliveryMethodOptions?.map((o) => (
                                                <MenuItem key={o.id} value={o.option}>
                                                    {o.option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel id='payment-temrs-label' shrink={true} required={true}>
                                            Maksuehto
                                        </InputLabel>
                                        <Select
                                            id='payment-temrs'
                                            labelId='payment-temrs-label'
                                            className={classes.select}
                                            value={formSimpleData.paymentTerms}
                                            onChange={(e) => handleFormSimpleDataSelectChange('paymentTerms', e)}>
                                            {simpleData?.paymentTermsOptions?.map((o) => (
                                                <MenuItem key={o.id} value={o.option}>
                                                    {o.option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Box mt={1}>
                                <Tooltip
                                    open={!!submitDisabledText(formSimpleData)}
                                    title={submitDisabledText(formSimpleData)}
                                    arrow>
                                    <span>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            disabled={!!submitDisabledText(formSimpleData)}
                                            onClick={handleShowSubmitConfirmationModal}>
                                            Lähetä tarjous
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Dialog
                                    classes={{ paper: classes.modal }}
                                    open={showConfirmationModal}
                                    onClose={() => setShowConfirmationModal(false)}
                                    aria-labelledby='Tilauksen lähettämisen varmistus'
                                    aria-describedby='offer-submission-confirmation'>
                                    <DialogContent>
                                        <DialogContentText>
                                            Oletko varma että haluat lähettää tarjouksen?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant='contained' color='primary' onClick={handleOfferSubmission}>
                                            Lähetä tarjous
                                        </Button>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            onClick={() => setShowConfirmationModal(false)}>
                                            Peruuta
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </StyledContainer>
    ) : (
        <span>Ladataan...</span>
    );
};

export default Offer;
