import { combineEpics, Epic } from 'redux-observable';
import { filter, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { api } from '../../utils/api';
import {
    ASSIGN_OFFER,
    ASSIGN_OFFER_SUCCESS,
    AssignActionTypes,
    AssignState,
    CHANGE_OFFERS_PAGE,
    GET_ASSIGNED_OFFERS,
    GET_ASSIGNED_OFFERS_SUCCESS,
    GET_STAFF,
    GET_STAFF_SUCCESS,
    GET_UNASSIGNED_OFFERS,
    GET_UNASSIGNED_OFFERS_SUCCESS,
    User,
} from './types';
import {
    assignOfferError,
    assignOfferSuccess,
    getAssignedOffersError,
    getAssignedOffersSuccess,
    getStaffError,
    getStaffSuccess,
    getUnassignedOffersError,
    getUnassignedOffersSuccess,
} from './actions';
import { ANSWER_PROPOSAL_SUCCESS } from '../my-offers/types';
import { RootState } from '..';
import { OfferRequestData } from '../offerRequest/types';
import { isOfType } from 'typesafe-actions';

const initialState: AssignState = {
    offers: undefined,
    offersPage: 0,
    offersSize: 0,
    assigned: {},
    assignedTotalCounts: {},
    staff: undefined,
};

const assign = (state = initialState, action: AssignActionTypes): AssignState => {
    switch (action.type) {
        case GET_UNASSIGNED_OFFERS_SUCCESS:
            return { ...state, offers: action.payload.offers, offersSize: action.payload.size };
        case CHANGE_OFFERS_PAGE:
            return { ...state, offersPage: action.payload.page };
        case GET_ASSIGNED_OFFERS_SUCCESS:
            return {
                ...state,
                assigned: {
                    ...state.assigned,
                    [action.payload.person]: {
                        ...state.assigned[action.payload.person],
                        [action.payload.page]: action.payload.offers,
                    },
                },
                assignedTotalCounts: {
                    ...state.assignedTotalCounts,
                    [action.payload.person]: action.payload.size,
                },
            };
        case GET_STAFF_SUCCESS:
            return { ...state, staff: action.payload.staff };
        default:
            return state;
    }
};

export default assign;

export const getUnassignedOffersEpic: Epic<AssignActionTypes, AssignActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType([GET_UNASSIGNED_OFFERS, ASSIGN_OFFER_SUCCESS, ANSWER_PROPOSAL_SUCCESS])),
        mergeMap(() =>
            api
                .get<OfferRequestData[]>(
                    '/offerRequest',
                    {
                        filter: {
                            status: ['SENT', 'UNASSIGNED'],
                        },
                        sort: 'updated,ASC',
                        page: `${state$.value.assign.offersPage}`,
                        size: '10',
                    },
                    {},
                )
                .pipe(
                    map((res) => getUnassignedOffersSuccess(res)),
                    catchError((error) => of(getUnassignedOffersError(error))),
                ),
        ),
    );

export const getAssignedOffersEpic: Epic<AssignActionTypes, AssignActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(GET_ASSIGNED_OFFERS)),
        mergeMap((action) =>
            api
                .get<OfferRequestData[]>(
                    '/offerRequest',
                    {
                        filter: {
                            handler: action.payload.person,
                            status: ['ASSIGNED', 'HANDLING'],
                            assignmentStatus: ['ACCEPT', 'WAITING'],
                        },
                        sort: 'updated,ASC',
                        page: `${action.payload.page}`,
                        size: '10',
                    },
                    {},
                )
                .pipe(
                    map((res) => getAssignedOffersSuccess(res, action.payload.person, action.payload.page)),
                    catchError((error) => of(getAssignedOffersError(error))),
                ),
        ),
    );

export const getStaffEpic: Epic<AssignActionTypes, AssignActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(GET_STAFF)),
        mergeMap(() =>
            api
                .get<User[]>(
                    '/user',
                    {
                        filter: {
                            role: ['ADMIN', 'STAFF'],
                        },
                    },
                    {},
                )
                .pipe(
                    map((res) => getStaffSuccess(res)),
                    catchError((error) => of(getStaffError(error))),
                ),
        ),
    );

export const assignOfferEpic: Epic<AssignActionTypes, AssignActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(ASSIGN_OFFER)),
        mergeMap((action) =>
            api.patch(`/offerRequest/${action.payload.offerId}/assign/${action.payload.staffId}`, {}, {}).pipe(
                map(() => assignOfferSuccess()),
                catchError((error) => of(assignOfferError(error))),
            ),
        ),
    );

export const epics = combineEpics(getUnassignedOffersEpic, getAssignedOffersEpic, getStaffEpic, assignOfferEpic);
