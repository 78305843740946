import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { parse as parseCR } from 'content-range';

import {
    CHANGE_PAGE,
    ChangePageAction,
    GET_MY_OFFER_REQUEST,
    GET_MY_OFFER_REQUESTS_SUCCESS,
    GET_MY_OFFER_REQUESTS_ERROR,
    GetMyOfferRequestsAction,
    GetMyOfferRequestsSuccessAction,
    GetMyOfferRequestsErrorAction,
    ANSWER_PROPOSAL,
    ANSWER_PROPOSAL_SUCCESS,
    ANSWER_PROPOSAL_ERROR,
    AnswerProposalAction,
    AnswerProposalSuccessAction,
    AnswerProposalErrorAction,
} from './types';
import { OfferRequestData } from '../offerRequest/types';

export const changePage = (page: number, type: string): ChangePageAction => ({
    type: CHANGE_PAGE,
    payload: {
        page,
        type,
    },
});

export const getMyOfferRequests = (): GetMyOfferRequestsAction => ({
    type: GET_MY_OFFER_REQUEST,
});

export const getMyOfferRequestsSuccess = (
    res: AjaxResponse<OfferRequestData[]>,
    type: string,
): GetMyOfferRequestsSuccessAction => ({
    type: GET_MY_OFFER_REQUESTS_SUCCESS,
    payload: {
        offers: res.response,
        type,
        size: parseCR(res.xhr.getResponseHeader('content-range') || '')?.size || 0,
    },
});

export const getMyOfferRequestsError = (error: AjaxError): GetMyOfferRequestsErrorAction => ({
    type: GET_MY_OFFER_REQUESTS_ERROR,
    error,
});

export const answerProposal = (id: string, reason: string, decision: string): AnswerProposalAction => {
    const payload = {
        id,
        reason,
        decision,
    };

    if (reason) {
        payload.reason = reason;
    }

    return {
        type: ANSWER_PROPOSAL,
        payload,
    };
};

export const answerProposalSuccess = (): AnswerProposalSuccessAction => ({
    type: ANSWER_PROPOSAL_SUCCESS,
});

export const answerProposalError = (error: AjaxError): AnswerProposalErrorAction => ({
    type: ANSWER_PROPOSAL_ERROR,
    error,
});
