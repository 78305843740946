import { AjaxError, AjaxResponse } from 'rxjs/ajax';

import {
    Customer,
    GET_CUSTOMERS,
    GET_CUSTOMERS_ERROR,
    GET_CUSTOMERS_SUCCESS,
    GET_OFFER_REQUESTS,
    GET_OFFER_REQUESTS_ERROR,
    GET_OFFER_REQUESTS_SUCCESS,
    GetCustomersAction,
    GetCustomersErrorAction,
    GetCustomersSuccessAction,
    GetOfferRequestsAction,
    GetOfferRequestsErrorAction,
    GetOfferRequestsSuccessAction,
    SELECT_CUSTOMER,
    SelectCustomerAction,
} from './types';
import { OfferRequestData } from '../offerRequest/types';

export const getCustomers = (): GetCustomersAction => ({
    type: GET_CUSTOMERS,
});

export const getCustomersSuccess = (res: AjaxResponse<Customer[]>): GetCustomersSuccessAction => ({
    type: GET_CUSTOMERS_SUCCESS,
    payload: res.response,
});

export const getCustomersError = (error: AjaxError): GetCustomersErrorAction => ({
    type: GET_CUSTOMERS_ERROR,
    error,
});

export const selectCustomer = (selectedCustomer: Customer['id']): SelectCustomerAction => ({
    type: SELECT_CUSTOMER,
    payload: selectedCustomer,
});

export const getOfferRequests = (): GetOfferRequestsAction => ({
    type: GET_OFFER_REQUESTS,
});

export const getOfferRequestsSuccess = (res: AjaxResponse<OfferRequestData[]>): GetOfferRequestsSuccessAction => ({
    type: GET_OFFER_REQUESTS_SUCCESS,
    payload: res.response,
});

export const getOfferRequestsError = (error: AjaxError): GetOfferRequestsErrorAction => ({
    type: GET_OFFER_REQUESTS_ERROR,
    error,
});
