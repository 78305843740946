import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';
import { OfferRequestData } from '../offerRequest/types';

// State
export type Pages = Record<string, OfferRequestData[]>;

export interface MyOffersState extends Record<string, number | Pages> {
    proposed: Pages;
    proposedPage: number;
    proposedSize: number;
    accepted: Pages;
    acceptedPage: number;
    acceptedSize: number;
    done: Pages;
    donePage: number;
    doneSize: number;
}

// Action types
export const CHANGE_PAGE = 'my-offers:CHANGE_PAGE';
export const GET_MY_OFFER_REQUEST = 'my-offers:GET_MY_OFFER_REQUESTS';
export const GET_MY_OFFER_REQUESTS_SUCCESS = 'my-offers:GET_MY_OFFER_REQUESTS_SUCCESS';
export const GET_MY_OFFER_REQUESTS_ERROR = 'my-offers:GET_MY_OFFER_REQUESTS_ERROR';
export const ANSWER_PROPOSAL = 'my-offers:ANSWER_PROPOSAL';
export const ANSWER_PROPOSAL_SUCCESS = 'my-offers:ANSWER_PROPOSAL_SUCCESS';
export const ANSWER_PROPOSAL_ERROR = 'my-offers:ANSWER_PROPOSAL_ERROR';
export const COMPLETE_OFFER = 'my-offers:COMPLETE_OFFER';
export const COMPLETE_OFFER_SUCCESS = 'my-offers:COMPLETE_OFFER_SUCCESS';
export const COMPLETE_OFFER_ERROR = 'my-offers:COMPLETE_OFFER_ERROR';

// Actions
export interface ChangePageAction extends Action {
    type: typeof CHANGE_PAGE;
    payload: {
        page: number;
        type: string;
    };
}

export interface GetMyOfferRequestsAction extends Action {
    type: typeof GET_MY_OFFER_REQUEST;
}

export interface GetMyOfferRequestsSuccessAction extends Action {
    type: typeof GET_MY_OFFER_REQUESTS_SUCCESS;
    payload: {
        offers: OfferRequestData[];
        type: string;
        size: number;
    };
}

export interface GetMyOfferRequestsErrorAction extends Action {
    type: typeof GET_MY_OFFER_REQUESTS_ERROR;
    error: AjaxError;
}

export interface AnswerProposalAction extends Action {
    type: typeof ANSWER_PROPOSAL;
    payload: {
        id: string;
        reason?: string;
        decision: string;
    };
}

export interface AnswerProposalSuccessAction extends Action {
    type: typeof ANSWER_PROPOSAL_SUCCESS;
}

export interface AnswerProposalErrorAction extends Action {
    type: typeof ANSWER_PROPOSAL_ERROR;
    error: AjaxError;
}

export interface CompleteOfferAction extends Action {
    type: typeof COMPLETE_OFFER;
    payload: {
        id: string;
    };
}

export interface CompleteOfferSuccessAction extends Action {
    type: typeof COMPLETE_OFFER_SUCCESS;
}

export interface CompleteOfferErrorAction extends Action {
    type: typeof COMPLETE_OFFER_ERROR;
    error: AjaxError;
}

export type MyOffersActionTypes =
    | ChangePageAction
    | GetMyOfferRequestsAction
    | GetMyOfferRequestsSuccessAction
    | GetMyOfferRequestsErrorAction
    | AnswerProposalAction
    | AnswerProposalSuccessAction
    | AnswerProposalErrorAction
    | CompleteOfferAction
    | CompleteOfferSuccessAction
    | CompleteOfferErrorAction;
