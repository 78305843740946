import { combineEpics, Epic } from 'redux-observable';
import { EmptyObject } from 'redux';
import { filter, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import FileSaver from 'file-saver';
import { isOfType } from 'typesafe-actions';

import { api } from '../../utils/api';
import {
    AUTO_SAVE,
    AUTO_SAVE_SUCCESS,
    Customer,
    DOWNLOAD_OFFER_REQUEST_FILE,
    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_SAVED_ORDERS,
    GET_SAVED_ORDERS_SUCCESS,
    MAKE_NEW_OFFER_REQUEST,
    MAKE_NEW_OFFER_REQUEST_SUCCESS,
    OfferRequestActionTypes,
    OfferRequestData,
    OfferRequestState,
    REMOVE_FILE,
    SAVE_FILE,
    SAVE_FILE_SUCCESS,
    SUBMIT_OFFER_REQUEST,
    SUBMIT_OFFER_REQUEST_SUCCESS,
    USE_SAVED_ORDER_ID,
} from './types';
import { RootState } from '..';
import { IdWrapper } from '../types';
import {
    autoSaveError,
    autoSaveSuccess,
    downloadOfferRequestFileError,
    downloadOfferRequestFileSuccess,
    getCustomersError,
    getCustomersSuccess,
    getSavedOrderError,
    getSavedOrderSuccess,
    makeNewOfferRequestError,
    makeNewOfferRequestSuccess,
    removeFileError,
    removeFileSuccess,
    saveFileError,
    saveFileSuccess,
    submitOfferRequestError,
    submitOfferRequestSuccess,
} from './actions';
import { readFile } from '../tools';

const initialState: OfferRequestState = {
    id: null,
    savedOrders: null,
    fileIdReplace: undefined,
    customers: [],
    newCustomerId: undefined,
};

const offerRequest = (state = initialState, action: OfferRequestActionTypes): OfferRequestState => {
    switch (action.type) {
        case GET_CUSTOMERS_SUCCESS:
            return { ...state, customers: action.payload.customers };
        case MAKE_NEW_OFFER_REQUEST_SUCCESS:
            return { ...state, id: action.payload.id };
        case GET_SAVED_ORDERS_SUCCESS:
            return {
                ...state,
                savedOrders: action.payload.savedOrders.filter(
                    (o) => (o.customer && o.customer.customUser) || o.reference || o.message || o.offerTable,
                ),
            };
        case USE_SAVED_ORDER_ID:
            return { ...state, id: action.payload.id };
        case AUTO_SAVE_SUCCESS:
            return { ...state, newCustomerId: action.payload.newCustomerId };
        case SAVE_FILE_SUCCESS:
            return { ...state, fileIdReplace: { tmpId: action.payload.tmpId, id: action.payload.id } };
        case SUBMIT_OFFER_REQUEST_SUCCESS:
            return initialState;
        default:
            return state;
    }
};

export default offerRequest;

export const getCustomersEpic: Epic<OfferRequestActionTypes, OfferRequestActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(GET_CUSTOMERS)),
        mergeMap(() =>
            api.get<Customer[]>('/customer', { filter: { q: '' }, sort: 'customUser,ASC' }).pipe(
                map((res) => getCustomersSuccess(res)),
                catchError((error) => of(getCustomersError(error))),
            ),
        ),
    );

export const makeNewOfferRequestEpic: Epic<OfferRequestActionTypes, OfferRequestActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(MAKE_NEW_OFFER_REQUEST)),
        mergeMap(() =>
            api.post<OfferRequestData>('/offerRequest', {}, {}).pipe(
                map((res) => makeNewOfferRequestSuccess(res)),
                catchError((error) => of(makeNewOfferRequestError(error))),
            ),
        ),
    );

export const getSavedOrderEpic: Epic<OfferRequestActionTypes, OfferRequestActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(GET_SAVED_ORDERS)),
        mergeMap(() =>
            api
                .get<OfferRequestData[]>('/offerRequest', {
                    filter: {
                        status: 'SAVED',
                        user: state$.value.user.id || '',
                    },
                    sort: 'updated,DESC',
                })
                .pipe(
                    map((res) => getSavedOrderSuccess(res)),
                    catchError((error) => of(getSavedOrderError(error))),
                ),
        ),
    );

export const autoSaveEpic: Epic<OfferRequestActionTypes, OfferRequestActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(AUTO_SAVE)),
        map((action) => ({ ...action.payload.data, id: state$.value.offerRequest.id || '' })),
        mergeMap((requestBody) =>
            api.put<OfferRequestData>(`/offerRequest/${state$.value.offerRequest.id}`, requestBody).pipe(
                map((res) => autoSaveSuccess(res)),
                catchError((error) => of(autoSaveError(error))),
            ),
        ),
    );

export const saveFileEpic: Epic<OfferRequestActionTypes, OfferRequestActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(SAVE_FILE)),
        mergeMap((action) =>
            from(readFile(action.payload.file)).pipe(
                mergeMap((fileAsDataUrl) =>
                    api
                        .post<IdWrapper<string>>(`/offerRequest/${state$.value.offerRequest.id}/file`, {
                            data: fileAsDataUrl,
                            name: action.payload.name,
                        })
                        .pipe(
                            map((res) => saveFileSuccess(action.payload.tmpId, res)),
                            catchError((error) => of(saveFileError(error))),
                        ),
                ),
            ),
        ),
    );

export const removeFileEpic: Epic<OfferRequestActionTypes, OfferRequestActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(REMOVE_FILE)),
        mergeMap((action) =>
            api.delete(`/offerRequest/${state$.value.offerRequest.id}/file/${action.payload.id}`).pipe(
                map(() => removeFileSuccess()),
                catchError((error) => of(removeFileError(error))),
            ),
        ),
    );

export const submitOfferRequestEpic: Epic<OfferRequestActionTypes, OfferRequestActionTypes, RootState> = (
    action$,
    state$,
) =>
    action$.pipe(
        filter(isOfType(SUBMIT_OFFER_REQUEST)),
        mergeMap(() =>
            api.patch<EmptyObject>(`/offerRequest/${state$.value.offerRequest.id}/send`, {}).pipe(
                map((res) => submitOfferRequestSuccess(res)),
                catchError((error) => of(submitOfferRequestError(error))),
            ),
        ),
    );

export const downloadOfferRequestFileEpic: Epic<OfferRequestActionTypes, OfferRequestActionTypes, RootState> = (
    action$,
) =>
    action$.pipe(
        filter(isOfType(DOWNLOAD_OFFER_REQUEST_FILE)),
        mergeMap((action) =>
            api.blob(`/offerRequest/${action.payload.offerId}/file/${action.payload.fileId}`).pipe(
                map(
                    (res) => new Blob([res.response], { type: res.xhr.getResponseHeader('Content-Type') || undefined }),
                ),
                map((blob) => FileSaver.saveAs(blob, action.payload.name)),
                map(() => downloadOfferRequestFileSuccess()),
                catchError((error) => of(downloadOfferRequestFileError(error))),
            ),
        ),
    );

export const epics = combineEpics(
    makeNewOfferRequestEpic,
    getSavedOrderEpic,
    autoSaveEpic,
    saveFileEpic,
    removeFileEpic,
    submitOfferRequestEpic,
    getCustomersEpic,
    downloadOfferRequestFileEpic,
);
