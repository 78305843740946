import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Box, Grid, Icon } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridEvent, GridApi } from 'ag-grid-community';
import CustomCellEditor from './CustomCellEditor';

import { GridRow, OfferGridProps } from './types';
import { OGColumnDef } from '../../redux/offerRequest/types';

const PREFIX = 'OfferGrid';

const classes = {
    hideUndo: `${PREFIX}-hideUndo`,
};

const Root = styled('div')(() => ({
    '.ag-theme-alpine .ag-header-cell.ag-header-cell.ag-header-cell:not(:last-child)': {
        borderRight: '1px solid #dde2eb',
    },
    '.ag-theme-alpine .ag-cell.ag-cell.ag-cell:not(:last-child)': {
        borderRight: '1px solid #dde2eb',
    },
    '.ag-theme-alpine .ag-has-focus .ag-cell-focus.ag-cell-focus.ag-cell-focus:not(.ag-cell-range-selected)': {
        borderRight: '1px solid #2196f3',
    },
    '.ag-theme-alpine .ag-row.ag-row.ag-row:last-child': {
        borderBottom: 'none',
    },
    '.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine .ag-layout-print .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-print .ag-center-cols-container':
        {
            minHeight: 0,
        },

    [`& .${classes.hideUndo}`]: {
        display: 'none',
    },
}));

// Offer grid stuff
const frameworkComponents = {
    customCellEditor: CustomCellEditor,
};

export const columnDefs: OGColumnDef[] = [
    { field: '', checkboxSelection: true, suppressSizeToFit: true, width: 50, editable: false },
    { field: 'Määrä' },
    {
        field: 'Tuote',
        width: 250,
    },
    {
        field: 'Leveys',
        width: 150,
    },
    {
        field: 'Korkeus/ulottuma',
        width: 150,
    },
    {
        field: 'Lisätiedot',
        flex: 1,
    },
];

export const priceDef: OGColumnDef = {
    field: 'Hinta',
    width: 150,
};

const defaultColDef = {
    width: 100,
    editable: true,
    resizable: true,
    cellEditor: 'customCellEditor',
};

const OfferGrid: FC<OfferGridProps> = (props) => {
    // Apply global styles

    const [rowData, setRowData] = useState<GridRow[] | undefined>(undefined);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [showUndo, setShowUndo] = useState(false);

    // Load saved order
    useEffect(() => {
        const { loadedData, callbackGetGridData, includePrice } = props;

        if (includePrice && columnDefs.length === 6) {
            columnDefs.push(priceDef);
        }

        const newRowData: GridRow[] = [];
        if (loadedData && loadedData.length !== 0) {
            for (let i = 0; i < loadedData.length; i++) {
                const row: GridRow = { '': null };
                for (let j = 1; j < columnDefs.length; j++) {
                    row[columnDefs[j].field] = loadedData[i][j - 1];
                }
                newRowData.push(row);
            }
            setRowData(newRowData);
        } else {
            setRowData([]);
        }

        // Call this to update form data
        callbackGetGridData(newRowData, columnDefs);

        return function cleanup() {
            if (columnDefs.length === 7) {
                columnDefs.pop();
            }
        };
    }, [props.loadedData]);

    useEffect(() => {
        if (!rowData) return;
        if (rowData.length < 4) handleAddGridRow();
    }, [rowData]);

    const generateDefaultRow = (): GridRow => {
        const defaultRow: GridRow = {};
        for (let i = 0; i < columnDefs.length; i++) {
            defaultRow[columnDefs[i].field] = null;
        }
        return defaultRow;
    };

    const handleAddGridRow = () => {
        setRowData((rowData ?? []).concat(generateDefaultRow()));
    };

    const handleRemoveGridRows = () => {
        if (!rowData) return;
        const { callbackGetGridData } = props;
        const sRows = gridApi?.getSelectedNodes();
        const newRows = [];
        const removed = [];

        if (!sRows) {
            return;
        }

        const removeIndices = sRows.map((r) => r.rowIndex);
        for (let i = 0; i < rowData.length; i++) {
            if (removeIndices.indexOf(i) < 0) {
                newRows.push(rowData[i]);
            }
            // Collect removed rows
            else {
                removed.push([i, rowData[i]]);
            }
        }

        // Save removed rows
        sessionStorage.setItem('removedRows', JSON.stringify(removed));

        setShowUndo(true);
        setRowData(newRows);
        callbackGetGridData(newRows, columnDefs);
    };

    const handleUndoRemoveGridRows = () => {
        if (!rowData) return;
        const { callbackGetGridData } = props;

        const str = sessionStorage.getItem('removedRows');
        if (!str) {
            return;
        }
        const removed = JSON.parse(str);
        const newRows = [...rowData];

        for (let i = 0; i < removed.length; i++) {
            newRows.splice(removed[i][0], 0, removed[i][1]);
        }

        setShowUndo(false);
        setRowData(newRows);
        callbackGetGridData(newRows, columnDefs);
    };

    const handleGridDataChange = () => {
        if (!rowData) return;
        props.callbackGetGridData(rowData, columnDefs);
    };

    const onGridReady = (params: AgGridEvent) => {
        setGridApi(params.api);
    };

    return (
        <Root className='ag-theme-alpine'>
            <AgGridReact
                frameworkComponents={frameworkComponents}
                rowData={rowData}
                defaultColDef={defaultColDef}
                columnDefs={[...columnDefs, ...(props.includePrice && columnDefs.length === 6 ? [priceDef] : [])]}
                domLayout={'autoHeight'}
                onCellValueChanged={handleGridDataChange}
                singleClickEdit={true}
                stopEditingWhenCellsLoseFocus={true}
                rowSelection='multiple'
                onGridReady={onGridReady}
                suppressRowClickSelection={true}
            />
            <Box mt={1}>
                <Grid container justifyContent='flex-start' spacing={1}>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='secondary'
                            startIcon={<Icon>add</Icon>}
                            onClick={handleAddGridRow}>
                            Lisää rivi
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='secondary'
                            startIcon={<Icon>remove</Icon>}
                            onClick={handleRemoveGridRows}>
                            Poista valitut rivit
                        </Button>
                    </Grid>
                    <Grid item className={showUndo ? '' : classes.hideUndo}>
                        <Button
                            color='secondary'
                            variant='contained'
                            onClick={handleUndoRemoveGridRows}
                            startIcon={<Icon>undo</Icon>}>
                            Kumoa rivien poistaminen
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Root>
    );
};

export default OfferGrid;
