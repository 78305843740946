import { Grid, Icon, IconButton, List, ListItem } from '@mui/material';
import { OfferRequestData } from '../../redux/offerRequest/types';
import { styled } from '@mui/material/styles';
import React, { FC, useState } from 'react';
import OfferInfoModal from '../offer-info-modal';

const PREFIX = 'CustomerOffers';

const classes = {
    listPaper: `${PREFIX}-listPaper`,
    offer: `${PREFIX}-offer`,
    offerFirstRow: `${PREFIX}-offerFirstRow`,
    dateLabel: `${PREFIX}-dateLabel`,
    infoButtonWrapper: `${PREFIX}-infoButtonWrapper`,
    infoButton: `${PREFIX}-infoButton`,
};

const StyledList = styled(List)(({ theme }) => ({
    [`& .${classes.listPaper}`]: {
        boxShadow: theme.shadows[1],
        padding: 0,
    },

    [`& .${classes.offer}`]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
        },
        '&.on-time, &.late': {
            borderRight: `5px solid ${theme.palette.success.main}`,
        },
        '&.late': {
            borderRightColor: theme.palette.error.main,
        },
    },

    [`& .${classes.offerFirstRow}`]: {
        marginBottom: '.5rem',
    },

    [`& .${classes.dateLabel}`]: {
        display: 'inline-block',
        width: '200px',
        '&.short': {
            width: '120px',
        },
    },

    [`& .${classes.infoButtonWrapper}`]: {
        textAlign: 'right',
    },

    [`& .${classes.infoButton}`]: {
        padding: 0,
        transition: `opacity ${theme.transitions.duration.shortest}ms`,
        '&:hover': {
            opacity: 0.8,
        },
        marginRight: theme.spacing(1),
    },
}));

interface Props {
    offerRequests: OfferRequestData[];
}

const OfferRequestList: FC<Props> = ({ offerRequests }) => {
    const [infoModal, setInfoModal] = useState<OfferRequestData | null>(null);

    return (
        <>
            <StyledList className={classes.listPaper}>
                {offerRequests.map((offer) => (
                    <ListItem key={offer.id} className={classes.offer}>
                        <Grid container>
                            <Grid item xs={1} className={classes.offerFirstRow}>
                                {offer.number ? '#' + offer.number : ''}
                            </Grid>
                            <Grid item xs={4} className={classes.offerFirstRow}>
                                {offer.customer ? offer.customer.name : '-'}
                            </Grid>
                            <Grid item xs={5} className={classes.offerFirstRow}>
                                <span className={classes.dateLabel}>Jätetty:</span>
                                {offer.submissionTime ? new Date(offer.submissionTime).toLocaleString() : '-'}
                            </Grid>
                            <Grid item xs={2} className={classes.infoButtonWrapper}>
                                <IconButton
                                    className={classes.infoButton}
                                    edge='end'
                                    aria-label='info'
                                    onClick={() => setInfoModal(offer)}
                                    size='large'>
                                    <Icon>info</Icon>
                                </IconButton>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={4}>
                                {offer.reference || '-'}
                            </Grid>
                            <Grid item xs={5}>
                                <span className={classes.dateLabel}>Halutaan viimeistään:</span>
                                {offer.fast
                                    ? 'PIKA'
                                    : offer.deadlineWish
                                    ? new Date(offer.deadlineWish).toLocaleDateString()
                                    : '-'}
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
                {/* TODO: näytä lisää */}
                {offerRequests.length === 0 && <span>Ei tarjouspyyntöjä</span>}
            </StyledList>
            <OfferInfoModal open={Boolean(infoModal)} offerRequest={infoModal} handleClose={() => setInfoModal(null)} />
        </>
    );
};

export default OfferRequestList;
