import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';

import {
    addConfigOption,
    getConfig,
    getEmails,
    removeConfigOption,
    removeEmail,
    submitNewEmail,
    updateConfig,
    updateEmail,
} from '../../redux/settings/actions';

import { RootState } from '../../redux';
import {
    Email,
    emailStatuses,
    EmailType,
    OfferRequestStatus,
    offerRequestStatus,
    OfferStatus,
    offerStatus,
    SimpleData,
    Timing,
} from '../../redux/settings/types';

import TimingGrid from '../../components/timing-grid';
import { Delete } from '@mui/icons-material';

const PREFIX = 'Settings';

const classes = {
    select: `${PREFIX}-select`,
    bigTextField: `${PREFIX}-bigTextField`,
    bigTextFieldInput: `${PREFIX}-bigTextFieldInput`,
    emailHeader: `${PREFIX}-emailHeader`,
    configOptionName: `${PREFIX}-configOptionName`,
};

const StyledBox = styled(Box)(({ theme }) => ({
    '.mde-text': {
        fontSize: theme.typography.body1.fontSize,
    },
    '.mde-text:focus': {
        outline: 'none',
    },
    'th:not(:last-of-type)': {
        paddingRight: '1rem',
    },

    [`& .${classes.select}`]: {
        width: 163,
    },

    [`& .${classes.bigTextField}`]: {
        width: '100%',
        maxWidth: 500,
    },

    [`& .${classes.bigTextFieldInput}`]: {
        paddingTop: theme.spacing(0.5),
    },

    [`& .${classes.emailHeader}`]: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    [`& .${classes.configOptionName}`]: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
}));

const translate = (
    t: keyof typeof OfferRequestStatus | keyof typeof OfferStatus | keyof typeof Timing | EmailType,
): string => {
    switch (t) {
        case OfferRequestStatus.SAVED:
            return 'Tallennettu';
        case OfferRequestStatus.SENT:
            return 'Lähetetty';
        case OfferRequestStatus.ASSIGNED:
            return 'Kuormitettu';
        case OfferRequestStatus.UNASSIGNED:
            return 'Kuormitus hylätty vähintään kerran';
        case OfferRequestStatus.HANDLING:
            return 'Käsittelyssä';
        case OfferRequestStatus.DONE:
            return 'Valmis';
        case OfferStatus.DRAFT:
            return 'Luonnos';
        case OfferStatus.SENT:
            return 'Lähetetty';
        case OfferStatus.DELETED:
            return 'Poistettu';
        case Timing.INSTANT:
            return 'Välittömästi';
        case Timing.TOMORROW:
            return 'Seuraavana päivänä';
        case Timing.DAYS_AFTER:
            return 'X päivän kuluttua';
        case Timing.DAYS_BEFORE_DEADLINE_WISH:
            return 'X päivää ennen dl toivetta';
        case Timing.NEXT_WEEKDAY:
            return 'Seuraavana arkipäivänä';
        case Timing.NEXT_TARGET_DAY:
            return 'Seuraavana X päivänä';
        case Timing.NEXT_WEEK_TARGET_DAY:
            return 'Seuraavan viikon X päivänä';
        case Timing.NEXT_MONTH:
            return 'Seuraavan kuun X. päivä';
        case EmailType.OFFER:
            return 'Tarjoukset';
        case EmailType.OFFER_REQUEST:
            return 'Tarjouspyynnöt';
        default:
            return '???';
    }
};

const Settings: FC = () => {
    const dispatch = useDispatch();

    // Simple data
    const [simpleData, setSimpleData] = useState<SimpleData>({
        deadlineWishDefaultAfterDays: undefined,
        offerRequestInfo: '',
        tawkToLink: '',
        tawkToApiKey: '',
        tawkToHash: '',
        emailSender: '',
        infoPdfName: '',
    });
    const { simpleData: apiSimpleData } = useSelector((state: RootState) => state.settings);

    useEffect(() => {
        dispatch(getConfig());
        document.title = 'Tarjouspyyntöportaali - asetukset';
    }, []);

    useEffect(() => {
        setSimpleData({
            deadlineWishDefaultAfterDays: apiSimpleData.deadlineWishDefaultAfterDays,
            offerRequestInfo: apiSimpleData.offerRequestInfo || '',
            tawkToLink: apiSimpleData.tawkToLink || '',
            tawkToHash: apiSimpleData.tawkToHash || '',
            emailSender: apiSimpleData.emailSender || '',
            infoPdfName: apiSimpleData.infoPdfName || '',
        });
    }, [apiSimpleData, dispatch]);

    // Emails
    const [oldEmails, setOldEmails] = useState<Email[] | undefined>(undefined);
    const [emails, setEmails] = useState<Email[] | undefined>(undefined);
    const apiEmails = useSelector((state: RootState) => state.settings.emails);
    useEffect(() => {
        if (!apiEmails || !isEqual(apiEmails, emails)) {
            dispatch(getEmails());
        }
        setOldEmails(apiEmails);
        setEmails(apiEmails);
        setEmailSelectedTab(Array(apiEmails?.length || 0).fill('write'));
    }, [apiEmails, dispatch]);

    // New email
    const emptyNewEmail: Email = {
        '@type': EmailType.OFFER_REQUEST,
        recipients: [],
        subject: '',
        message: '',
        offerStatus: OfferStatus.SENT,
        offerRequestStatus: OfferRequestStatus.HANDLING,
        includeInfoPdf: false,
        includeFiles: false,
        includeOfferPdf: false,
        timings: [
            {
                id: 0,
                sendDay: Timing.INSTANT,
                sendDayTarget: 1,
                sendTime: '12:00',
            },
        ],
    };
    const [newEmail, setNewEmail] = useState<Email>(emptyNewEmail);

    const [acceptDialog, setAcceptDialog] = useState<{ show: boolean; id: string }>({
        show: false,
        id: '',
    });

    // Email MD
    const [emailSelectedTab, setEmailSelectedTab] = useState<('write' | 'preview')[]>([]);
    const [newEmailSelectedTab, setNewEmailSelectedTab] = useState<'write' | 'preview'>('write');
    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true,
    });

    const handleSimpleDataChange = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
        setSimpleData({ ...simpleData, [name]: event.target.value });
    };

    const handleSimpleDataSubmit = (
        name: keyof Omit<
            SimpleData,
            'deliveryTimeOptions' | 'deliveryConditionOptions' | 'deliveryMethodOptions' | 'paymentTermsOptions'
        >,
        allowEmpty = false,
    ) => {
        const val = simpleData[name];
        if (val || allowEmpty) {
            dispatch(updateConfig(name, val || ''));
        }
    };

    const handleNewEmailAddTiming = () => {
        setNewEmail({ ...newEmail, timings: newEmail.timings.concat(emptyNewEmail.timings[0]) });
    };

    const handleNewEmailRemoveTiming = (timings: number[]) => {
        setNewEmail({ ...newEmail, timings: newEmail.timings.filter((timing, index) => !timings.includes(index)) });
    };

    const handleNewEmailChange = (name: string, value: string | boolean | keyof typeof Timing) => {
        let newVal: string | string[] | boolean = '';
        switch (name) {
            case 'recipients':
                newVal = (value as string).split(/, |,/);
                break;
            default:
                newVal = value;
        }
        setNewEmail({ ...newEmail, [name]: newVal });
    };

    const handleNewEmailTimingChange = (
        timingIndex: number,
        name: string,
        value: string | number | keyof typeof Timing,
    ) => {
        const newVal = [
            ...newEmail.timings.slice(0, timingIndex),
            { ...newEmail.timings[timingIndex], [name]: value },
            ...newEmail.timings.slice(timingIndex + 1, newEmail.timings.length),
        ];
        setNewEmail({ ...newEmail, timings: newVal });
    };

    const handleNewEmailSubmit = () => {
        dispatch(submitNewEmail(newEmail));
        setNewEmail(emptyNewEmail);
    };

    const handleRemoveEmail = () => {
        dispatch(removeEmail(acceptDialog.id));
        if (emails) setEmails(emails.filter((email) => email.id !== acceptDialog.id));
        setAcceptDialog({ show: false, id: '' });
    };

    const handleEmailUpdate = (email: Email) => {
        if (oldEmails) {
            const oldEmail = oldEmails.find((oldEmail) => oldEmail.id === email.id);
            if (oldEmail) {
                const timingIds = email.timings.map((timing) => timing.id);
                const oldTimingIds = oldEmail.timings.map((timing) => timing.id);

                const addTimings = email.timings.filter((timing) => !oldTimingIds.includes(timing.id));
                const updateTimings = email.timings.filter(
                    (timing) => oldTimingIds.includes(timing.id) && !oldEmail.timings.includes(timing),
                );
                const deleteTimings = oldEmail.timings.filter((timing) => !timingIds.includes(timing.id));

                dispatch(updateEmail(email, addTimings, updateTimings, deleteTimings));

                const oldEmailIndex = oldEmails.indexOf(oldEmail);
                setOldEmails([
                    ...oldEmails.slice(0, oldEmailIndex),
                    email,
                    ...oldEmails.slice(oldEmailIndex + 1, oldEmails.length),
                ]);
            }
        }
    };

    const handleEmailSelectedTabChange = (t: 'write' | 'preview', i: number) => {
        const nv = [...emailSelectedTab];
        nv.splice(i, 0, t);
        setEmailSelectedTab(nv);
    };

    const handleEmailAddTiming = (emailIndex: number) => {
        if (emails && emails[emailIndex]) {
            setEmails([
                ...emails.slice(0, emailIndex),
                {
                    ...emails[emailIndex],
                    timings: emails[emailIndex].timings.concat(emptyNewEmail.timings[0]),
                },
                ...emails.slice(emailIndex + 1, emails.length),
            ]);
        }
    };

    const handleEmailRemoveTiming = (emailIndex: number, timings: number[]) => {
        if (emails && emails[emailIndex]) {
            setEmails([
                ...emails.slice(0, emailIndex),
                {
                    ...emails[emailIndex],
                    timings: emails[emailIndex].timings.filter((timing, index) => !timings.includes(index)),
                },
                ...emails.slice(emailIndex + 1, emails.length),
            ]);
        }
    };

    const handleEmailChange = (emailIndex: number, name: string, value: string | boolean) => {
        let newVal: string | string[] | boolean = '';
        switch (name) {
            case 'recipients':
                newVal = (value as string).split(/, |,/);
                break;
            default:
                newVal = value;
        }
        if (emails && emails[emailIndex]) {
            setEmails([
                ...emails.slice(0, emailIndex),
                { ...emails[emailIndex], [name]: newVal },
                ...emails.slice(emailIndex + 1, emails.length),
            ]);
        }
    };

    const handleEmailTimingChange = (
        emailIndex: number,
        timingIndex: number,
        name: string,
        value: string | number | keyof typeof Timing,
    ) => {
        if (emails && emails[emailIndex]) {
            const email = emails[emailIndex];
            const newVal = [
                ...email.timings.slice(0, timingIndex),
                { ...email.timings[timingIndex], [name]: value },
                ...email.timings.slice(timingIndex + 1, email.timings.length),
            ];
            setEmails([
                ...emails.slice(0, emailIndex),
                { ...email, timings: newVal },
                ...emails.slice(emailIndex + 1, emails.length),
            ]);
        }
    };

    const [newOfferOptions, setNewOfferOptions] = useState<Record<string, string>>({
        DELIVERY_TIME: '',
        DELIVERY_CONDITION: '',
        DELIVERY_METHOD: '',
        PAYMENT_TERMS: '',
    });

    const handleNewOfferOptionsOnChange = (type: string, value: string) => {
        setNewOfferOptions({ ...newOfferOptions, [type]: value });
    };

    const handleNewOfferOptionsSubmit = (type: string) => {
        const option = newOfferOptions[type];
        if (option !== '') {
            dispatch(addConfigOption(type, option));
            setNewOfferOptions({ ...newOfferOptions, [type]: '' });
        }
    };

    const handleRemoveOfferOption = (type: string, id: string) => {
        dispatch(removeConfigOption(type, id));
    };

    return (
        <StyledBox mb={4}>
            <Container fixed>
                <Typography variant='h1' component='h1'>
                    Asetukset
                </Typography>
                <Grid container direction='column' spacing={2}>
                    <Grid item xs={12}>
                        <Box mb={0.3}>
                            <Typography variant='body1' component='p'>
                                Määritä kuinka monen päivän päästä tarjouksen jättöpäivä oletuksena on:
                            </Typography>
                        </Box>
                        <TextField
                            id='deadline-wish-days'
                            value={simpleData.deadlineWishDefaultAfterDays}
                            label='Oletuskäsittelyaika'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleSimpleDataChange('deadlineWishDefaultAfterDays', e)
                            }
                        />
                        <Box mt={1}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handleSimpleDataSubmit('deadlineWishDefaultAfterDays')}
                                disabled={
                                    apiSimpleData.deadlineWishDefaultAfterDays ===
                                    simpleData.deadlineWishDefaultAfterDays
                                }>
                                Päivitä
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={0.3}>
                            <Typography variant='body1' component='p'>
                                Jättöpäivän alla näkyvä infoteksti:
                            </Typography>
                        </Box>
                        <TextField
                            id='deadline-wish-info'
                            className={classes.bigTextField}
                            inputProps={{ className: classes.bigTextFieldInput }}
                            value={simpleData.offerRequestInfo}
                            label='Infoteksti'
                            InputLabelProps={{ shrink: true }}
                            multiline
                            rows={8}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleSimpleDataChange('offerRequestInfo', e)
                            }
                        />
                        <Box mt={1}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handleSimpleDataSubmit('offerRequestInfo')}
                                disabled={apiSimpleData.offerRequestInfo === simpleData.offerRequestInfo}>
                                Päivitä
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={0.3}>
                            <Typography variant='body1' component='p'>
                                Tawk.to: Suora linkki keskusteluun
                            </Typography>
                        </Box>
                        <TextField
                            id='tawk-to-link'
                            className={classes.bigTextField}
                            value={simpleData.tawkToLink}
                            label='Linkki'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleSimpleDataChange('tawkToLink', e)
                            }
                        />
                        <Box mb={0.3}>
                            <Typography variant='body1' component='p'>
                                Tawk.to: JavaScript API Key
                            </Typography>
                        </Box>
                        <TextField
                            id='tawk-to-api-key'
                            className={classes.bigTextField}
                            value={simpleData.tawkToApiKey}
                            placeholder={
                                simpleData.tawkToHash && simpleData.tawkToHash.length > 0 ? '********************' : ''
                            }
                            label='API avain'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleSimpleDataChange('tawkToApiKey', e)
                            }
                        />
                        <Box mt={1}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    handleSimpleDataSubmit('tawkToLink', true);
                                    handleSimpleDataSubmit('tawkToApiKey');
                                }}
                                disabled={
                                    apiSimpleData.tawkToLink === simpleData.tawkToLink &&
                                    apiSimpleData.tawkToApiKey === simpleData.tawkToApiKey
                                }>
                                Päivitä
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={0.3}>
                            <Typography variant='body1' component='p'>
                                Määritä sähköpostien lähettäjien nimi:
                            </Typography>
                        </Box>
                        <TextField
                            id='email-sender'
                            className={classes.bigTextField}
                            value={simpleData.emailSender}
                            label='Lähettäjä'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleSimpleDataChange('emailSender', e)
                            }
                        />
                        <Box mt={1}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handleSimpleDataSubmit('emailSender')}
                                disabled={apiSimpleData.emailSender === simpleData.emailSender}>
                                Päivitä
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={0.3}>
                            <Typography variant='body1' component='p'>
                                Määritä tilauksen tiedot sisältävän pdf-tiedoston nimi:
                            </Typography>
                        </Box>
                        <TextField
                            id='info-pdf-name'
                            className={classes.bigTextField}
                            value={simpleData.infoPdfName}
                            label='Nimi'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleSimpleDataChange('infoPdfName', e)
                            }
                        />
                        <Box mt={1}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handleSimpleDataSubmit('infoPdfName')}
                                disabled={apiSimpleData.infoPdfName === simpleData.infoPdfName}>
                                Päivitä
                            </Button>
                        </Box>
                        <Box p={1}>
                            <Typography variant='h4' component='h4'>
                                Avainsanat
                            </Typography>
                            {
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: converter.makeHtml(`
| Avain                 | Selite                              |
| :-------------------- | :---------------------------------- |
| {{ id }}              | Tarjouspyynnön id                   |
| {{ numero }}          | Käyttäjäystävällisempi tunniste     |
| {{ asiakas }}         | Asiakkaan nimi (sis. yrityksen)     |
| {{ viite }}           | Tarjouspyynnön viite                |
| {{ aikaraja }}        | Toivottu aikaraja tarjoukselle      |
`),
                                    }}
                                />
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box my={2}>
                    <Typography variant='h2' component='h2'>
                        Sähköpostipohjat
                    </Typography>
                </Box>
                <Grid container direction='column' spacing={2}>
                    {emails &&
                        emails.map((email, emailIndex) => (
                            <Accordion key={emailIndex} TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'>
                                    <Typography className={classes.emailHeader}>{`${emailIndex + 1}. ${
                                        apiEmails
                                            ? translate(apiEmails[emailIndex]['@type']) +
                                              ' - ' +
                                              apiEmails[emailIndex].subject
                                            : ''
                                    }`}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction='column' spacing={2}>
                                        <Grid item>
                                            <TextField
                                                id={`email-${emailIndex}-subject`}
                                                className={classes.bigTextField}
                                                value={email.subject || ''}
                                                label='Otsikko'
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) =>
                                                    handleEmailChange(emailIndex, 'subject', e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <ReactMde
                                                value={email.message}
                                                onChange={(v) => handleEmailChange(emailIndex, 'message', v)}
                                                selectedTab={emailSelectedTab[emailIndex]}
                                                onTabChange={(t) => handleEmailSelectedTabChange(t, emailIndex)}
                                                generateMarkdownPreview={(markdown) =>
                                                    Promise.resolve(converter.makeHtml(markdown))
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            {email['@type'] === 'OfferRequest' && (
                                                <Box>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={email.includeInfoPdf}
                                                                onChange={(e) =>
                                                                    handleEmailChange(
                                                                        emailIndex,
                                                                        'includeInfoPdf',
                                                                        e.target.checked,
                                                                    )
                                                                }
                                                                name={`email-${emailIndex}-include-info-pdf`}
                                                            />
                                                        }
                                                        label='Liitä tilauksen tiedot pdf:nä sähköpostiin'
                                                    />
                                                </Box>
                                            )}
                                            {email['@type'] === 'OfferRequest' && (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={email.includeFiles}
                                                            onChange={(e) =>
                                                                handleEmailChange(
                                                                    emailIndex,
                                                                    'includeFiles',
                                                                    e.target.checked,
                                                                )
                                                            }
                                                            name={`email-${emailIndex}-include-files`}
                                                        />
                                                    }
                                                    label='Liitä tilauksen liitteet myös sähköpostiin'
                                                />
                                            )}
                                            {email['@type'] === 'Offer' && (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={email.includeOfferPdf}
                                                            onChange={(e) =>
                                                                handleEmailChange(
                                                                    emailIndex,
                                                                    'includeOfferPdf',
                                                                    e.target.checked,
                                                                )
                                                            }
                                                            name={`email-${emailIndex}-include-offer-pdf`}
                                                        />
                                                    }
                                                    label='Liitä tarjous pdf:nä sähköpostiin'
                                                />
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body1' component='p' paragraph>
                                                Lisää vastaannottajat pilkulla eroteltuna. Vastaanottajat ovat
                                                lähtökohtaisesti sähköpostiosoitteita. Voit kuitenkin käyttää ennalta
                                                määriteltyjä osoitteita: "customer" ja "handler"
                                            </Typography>
                                            <TextField
                                                id={`email-${emailIndex}-recipients`}
                                                value={email.recipients.join(', ')}
                                                label='Vastaanottajat'
                                                InputLabelProps={{ shrink: true }}
                                                style={{ width: '100%', maxWidth: 500 }}
                                                onChange={(e) =>
                                                    handleEmailChange(emailIndex, 'recipients', e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <InputLabel id={`email-${emailIndex}-status-label`} shrink={true}>
                                                    Status
                                                </InputLabel>
                                                {email['@type'] === 'OfferRequest' && (
                                                    <Select
                                                        id={`email-${emailIndex}-status`}
                                                        labelId={`email-${emailIndex}-status-label`}
                                                        className={classes.select}
                                                        value={email.offerRequestStatus}
                                                        onChange={(e) =>
                                                            handleEmailChange(
                                                                emailIndex,
                                                                'offerRequestStatus',
                                                                e.target.value as keyof typeof OfferRequestStatus,
                                                            )
                                                        }>
                                                        {offerRequestStatus
                                                            .filter((status) => status !== OfferRequestStatus.SAVED)
                                                            .map((status) => (
                                                                <MenuItem key={status} value={status}>
                                                                    {translate(status)}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                )}
                                                {email['@type'] === 'Offer' && (
                                                    <Select
                                                        id={`email-${emailIndex}-status`}
                                                        labelId={`email-${emailIndex}-status-label`}
                                                        className={classes.select}
                                                        value={email.offerStatus}
                                                        onChange={(e) =>
                                                            handleEmailChange(
                                                                emailIndex,
                                                                'offerStatus',
                                                                e.target.value as keyof typeof OfferStatus,
                                                            )
                                                        }>
                                                        {offerStatus.map((status) => (
                                                            <MenuItem key={status} value={status}>
                                                                {translate(status)}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <TimingGrid
                                                emailIndex={emailIndex}
                                                email={email}
                                                oldEmail={true}
                                                translate={translate}
                                                handleEmailAddTiming={handleEmailAddTiming}
                                                handleEmailRemoveTiming={handleEmailRemoveTiming}
                                                handleEmailTimingChange={handleEmailTimingChange}
                                                handleNewEmailAddTiming={handleNewEmailAddTiming}
                                                handleNewEmailRemoveTiming={handleNewEmailRemoveTiming}
                                                handleNewEmailTimingChange={handleNewEmailTimingChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={() => handleEmailUpdate(email)}
                                                disabled={
                                                    apiEmails &&
                                                    emails &&
                                                    isEqual(apiEmails[emailIndex], emails[emailIndex])
                                                }>
                                                Päivitä sähköpostipohja
                                            </Button>
                                            <Button
                                                style={{ marginLeft: '1.2rem' }}
                                                variant='contained'
                                                color='secondary'
                                                onClick={() =>
                                                    setAcceptDialog({
                                                        show: true,
                                                        id: email.id || '',
                                                    })
                                                }>
                                                Poista sähköpostipohja
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    <Dialog open={acceptDialog.show} onClose={() => setAcceptDialog({ show: false, id: '' })}>
                        <DialogContent>
                            <DialogContentText>Oletko varma että haluat poistaa sähköpostipohjan?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' color='primary' onClick={handleRemoveEmail}>
                                Kyllä
                            </Button>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={() => setAcceptDialog({ show: false, id: '' })}>
                                Peruuta
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {(!emails || emails.length === 0) && (
                        <Box mx={2} mt={1}>
                            <Typography variant='body1' component='p' paragraph>
                                Ei olemassaolevia sähköpostipohjia.
                            </Typography>
                        </Box>
                    )}
                    <Grid item xs={12}>
                        <Typography variant='h3' component='h3'>
                            Uusi sähköpostipohja
                        </Typography>
                        <Grid container direction='column' spacing={1}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <InputLabel id='new-email-type-label' shrink={true}>
                                        Sähköpostin tyyppi
                                    </InputLabel>
                                    <Select
                                        id='new-email-type'
                                        labelId='new-email-type-label'
                                        className={classes.select}
                                        value={newEmail['@type']}
                                        onChange={(e) =>
                                            handleNewEmailChange('@type', e.target.value as keyof typeof EmailType)
                                        }>
                                        {emailStatuses.map((status) => (
                                            <MenuItem key={status} value={status}>
                                                {translate(status)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id={`new-email-subject`}
                                    className={classes.bigTextField}
                                    value={newEmail.subject}
                                    label='Otsikko'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => handleNewEmailChange('subject', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ReactMde
                                    value={newEmail.message}
                                    onChange={(v) => handleNewEmailChange('message', v)}
                                    selectedTab={newEmailSelectedTab}
                                    onTabChange={setNewEmailSelectedTab}
                                    generateMarkdownPreview={(markdown) =>
                                        Promise.resolve(converter.makeHtml(markdown))
                                    }
                                />
                                <Box p={1}>
                                    <Typography variant='h4' component='h4'>
                                        Vinkkejä Markdownin käyttöön
                                    </Typography>
                                    <Typography variant='body1'>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: converter.makeHtml(`
Rivienvaihdon ilman väliä saat laittamalla kaksi välilyöntiä rivin loppuun.

Linkit ovat muotoa:

    [linkin teksti](https://linkki.fi/)

`),
                                            }}
                                        />
                                    </Typography>
                                </Box>
                                <Box p={1}>
                                    <Typography variant='h4' component='h4'>
                                        Avainsanat
                                    </Typography>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: converter.makeHtml(
                                                `
| Avain                 | Selite                              | Otsikossa | Viestissä |
| :-------------------- | :---------------------------------- | :-------: | :-------: |
| {{ id }}              | Tarjouspyynnön id                   |         - |         ✔ |
| {{ numero }}          | Käyttäjäystävällisempi tunniste     |         ✔ |         ✔ |
| {{ asiakas }}         | Asiakkaan nimi (sis. yrityksen)     |         ✔ |         ✔ |
| {{ käsittelijä }}     | Käsittelijän nimi                   |         ✔ |         ✔ |
| {{ viite }}           | Tarjouspyynnön viite                |         ✔ |         ✔ |
| {{ viesti }}          | Tarjouspyynnön viesti               |         - |         ✔ |
| {{ aikaraja }}        | Toivottu aikaraja tarjoukselle      |         ✔ |         ✔ |
| {{ tila }}            | Tarjouspyynnön tila                 |         - |         ✔ |
| {{ tiedosto_määrä }}  | Tiedostojen määrä                   |         - |         ✔ |
`,
                                            ),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {newEmail['@type'] === 'OfferRequest' && (
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={newEmail.includeInfoPdf}
                                                    onChange={(e) =>
                                                        handleNewEmailChange('includeInfoPdf', e.target.checked)
                                                    }
                                                    name='newEmailIncludeInfoPdf'
                                                />
                                            }
                                            label='Liitä tilauksen tiedot pdf:nä sähköpostiin'
                                        />
                                    </Box>
                                )}
                                {newEmail['@type'] === 'OfferRequest' && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={newEmail.includeFiles}
                                                onChange={(e) => handleNewEmailChange('includeFiles', e.target.checked)}
                                                name='newEmailIncludeFiles'
                                            />
                                        }
                                        label='Liitä tilauksen liitteet myös sähköpostiin'
                                    />
                                )}
                                {newEmail['@type'] === 'Offer' && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={newEmail.includeOfferPdf}
                                                onChange={(e) =>
                                                    handleNewEmailChange('includeOfferPdf', e.target.checked)
                                                }
                                                name='newEmailIncludeOfferPdf'
                                            />
                                        }
                                        label='Liitä tarjous pdf:nä sähköpostiin'
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body1' component='p' paragraph>
                                    Lisää vastaannottajat pilkulla eroteltuna. Vastaanottajat ovat lähtökohtaisesti
                                    sähköpostiosoitteita. Voit kuitenkin käyttää ennalta määriteltyjä osoitteita:
                                    "customer" ja "handler"
                                </Typography>
                                <TextField
                                    id={`new-email-recipients`}
                                    value={newEmail.recipients}
                                    label='Vastaanottajat'
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '100%', maxWidth: 600 }}
                                    onChange={(e) => handleNewEmailChange('recipients', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <InputLabel id='new-email-status-label' shrink={true}>
                                        Status
                                    </InputLabel>
                                    {newEmail['@type'] === 'OfferRequest' && (
                                        <Select
                                            id={`new-email-status`}
                                            labelId='new-email-status-label'
                                            className={classes.select}
                                            value={newEmail.offerRequestStatus}
                                            onChange={(e) =>
                                                handleNewEmailChange(
                                                    'offerRequestStatus',
                                                    e.target.value as keyof typeof OfferRequestStatus,
                                                )
                                            }>
                                            {offerRequestStatus
                                                .filter((status) => status !== OfferRequestStatus.SAVED)
                                                .map((status) => (
                                                    <MenuItem key={status} value={status}>
                                                        {translate(status)}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    )}
                                    {newEmail['@type'] === 'Offer' && (
                                        <Select
                                            id={`new-email-status`}
                                            labelId='new-email-status-label'
                                            className={classes.select}
                                            value={newEmail.offerStatus}
                                            onChange={(e) =>
                                                handleNewEmailChange(
                                                    'offerStatus',
                                                    e.target.value as keyof typeof OfferStatus,
                                                )
                                            }>
                                            {offerStatus.map((status) => (
                                                <MenuItem key={status} value={status}>
                                                    {translate(status)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TimingGrid
                                    emailIndex={0}
                                    email={newEmail}
                                    oldEmail={false}
                                    translate={translate}
                                    handleEmailAddTiming={handleEmailAddTiming}
                                    handleEmailRemoveTiming={handleEmailRemoveTiming}
                                    handleEmailTimingChange={handleEmailTimingChange}
                                    handleNewEmailAddTiming={handleNewEmailAddTiming}
                                    handleNewEmailRemoveTiming={handleNewEmailRemoveTiming}
                                    handleNewEmailTimingChange={handleNewEmailTimingChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={1}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => handleNewEmailSubmit()}
                                        disabled={isEqual(emptyNewEmail, newEmail)}>
                                        Lisää uusi sähköpostipohja
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box my={2}>
                    <Typography variant='h2' component='h2'>
                        Tarjousten vaihtoehdot
                    </Typography>
                </Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Box mt={1}>
                            <Typography variant='h3' component='h2'>
                                Toimitusaika
                            </Typography>
                        </Box>
                        <List>
                            {apiSimpleData?.deliveryTimeOptions?.map((o) => (
                                <ListItem>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <div className={classes.configOptionName}>{o.option}</div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton
                                                onClick={() => {
                                                    handleRemoveOfferOption('DELIVERY_TIME', o.id);
                                                }}
                                                size='small'>
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                        <Box mt={1}>
                            <TextField
                                id='addDeliveryTimeOption'
                                value={newOfferOptions.deliveryTimeOption}
                                label='Lisää uusi toimitusehto'
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '100%', maxWidth: 500 }}
                                onChange={(e) => handleNewOfferOptionsOnChange('DELIVERY_TIME', e.target.value)}
                            />
                        </Box>
                        <Box my={1}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handleNewOfferOptionsSubmit('DELIVERY_TIME')}
                                disabled={newOfferOptions.deliveryTimeOption === ''}>
                                Lisää
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={1}>
                            <Typography variant='h3' component='h2'>
                                Toimitusehto
                            </Typography>
                        </Box>
                        <List>
                            {apiSimpleData?.deliveryConditionOptions?.map((o) => (
                                <ListItem>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <div className={classes.configOptionName}>{o.option}</div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton
                                                onClick={() => {
                                                    handleRemoveOfferOption('DELIVERY_CONDITION', o.id);
                                                }}
                                                size='small'>
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                        <Box mt={1}>
                            <TextField
                                id='addDeliveryConditionOption'
                                value={newOfferOptions.deliveryConditionOption}
                                label='Lisää uusi toimitusaika'
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '100%', maxWidth: 500 }}
                                onChange={(e) => handleNewOfferOptionsOnChange('DELIVERY_CONDITION', e.target.value)}
                            />
                        </Box>
                        <Box my={1}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handleNewOfferOptionsSubmit('DELIVERY_CONDITION')}
                                disabled={newOfferOptions.deliveryConditionOption === ''}>
                                Lisää
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={1}>
                            <Typography variant='h3' component='h2'>
                                Toimitustapa
                            </Typography>
                        </Box>
                        <List>
                            {apiSimpleData?.deliveryMethodOptions?.map((o) => (
                                <ListItem>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <div className={classes.configOptionName}>{o.option}</div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton
                                                onClick={() => {
                                                    handleRemoveOfferOption('DELIVERY_METHOD', o.id);
                                                }}
                                                size='small'>
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                        <Box mt={1}>
                            <TextField
                                id='addDeliveryMethodOption'
                                value={newOfferOptions.deliveryMethodOption}
                                label='Lisää uusi toimitusaika'
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '100%', maxWidth: 500 }}
                                onChange={(e) => handleNewOfferOptionsOnChange('DELIVERY_METHOD', e.target.value)}
                            />
                        </Box>
                        <Box my={1}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handleNewOfferOptionsSubmit('DELIVERY_METHOD')}
                                disabled={newOfferOptions.deliveryMethodOption === ''}>
                                Lisää
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={1}>
                            <Typography variant='h3' component='h2'>
                                Maksuehto
                            </Typography>
                        </Box>
                        <List>
                            {apiSimpleData?.paymentTermsOptions?.map((o) => (
                                <ListItem>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <div className={classes.configOptionName}>{o.option}</div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton
                                                onClick={() => {
                                                    handleRemoveOfferOption('PAYMENT_TERMS', o.id);
                                                }}
                                                size='small'>
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                        <Box mt={1}>
                            <TextField
                                id='addPaymentTermsOption'
                                value={newOfferOptions.paymentTermsOption}
                                label='Lisää uusi maksuehto'
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '100%', maxWidth: 500 }}
                                onChange={(e) => handleNewOfferOptionsOnChange('PAYMENT_TERMS', e.target.value)}
                            />
                        </Box>
                        <Box my={1}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handleNewOfferOptionsSubmit('PAYMENT_TERMS')}
                                disabled={newOfferOptions.paymentTermsOption === ''}>
                                Lisää
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </StyledBox>
    );
};

export default Settings;
