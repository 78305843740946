import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { reducer as oidcReducer } from 'redux-oidc';

import user, { epics as userEpics } from './user';
import offerRequest, { epics as offerRequestEpics } from './offerRequest';
import assign, { epics as assignEpics } from './assign';
import myOffers, { epics as myOffersEpics } from './my-offers';
import offer, { epics as offerEpics } from './offer';
import settings, { epics as settingsEpics } from './settings';
import statistics, { epics as statisticEpics } from './statistics';
import customerOffers, { epics as customerOffersEpics } from './customer-offers';

const rootReducer = combineReducers({
    oidc: oidcReducer,
    user,
    offerRequest,
    customerOffers,
    offer,
    assign,
    myOffers,
    settings,
    statistics,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

export const rootEpic = combineEpics<any, any, RootState>(
    userEpics,
    offerRequestEpics,
    customerOffersEpics,
    assignEpics,
    offerEpics,
    myOffersEpics,
    settingsEpics,
    statisticEpics,
);
