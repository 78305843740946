import { combineEpics, Epic } from 'redux-observable';
import { filter, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { RootState } from '..';
import { api } from '../../utils/api';
import { OfferRequestData } from '../offerRequest/types';
import {
    autoSaveError,
    autoSaveSuccess,
    createOfferError,
    createOfferSuccess,
    getOfferRequestError,
    getOfferRequestSuccess,
    removeFileError,
    removeFileSuccess,
    saveFileError,
    saveFileSuccess,
    submitOfferError,
    submitOfferSuccess,
} from './actions';
import {
    AUTO_SAVE,
    CREATE_OFFER,
    CREATE_OFFER_SUCCESS,
    GET_OFFER_REQUEST,
    GET_OFFER_REQUEST_SUCCESS,
    OfferActionTypes,
    OfferData,
    OfferState,
    REMOVE_FILE,
    RESET_OFFER_PAGE,
    SAVE_FILE,
    SAVE_FILE_SUCCESS,
    SUBMIT_OFFER,
} from './types';
import { IdWrapper } from '../types';
import { readFile } from '../tools';

const initialState: OfferState = {
    withoutOfferRequest: true,
    id: undefined,
    offerData: undefined,
    offerRequestData: undefined,
    fileIdReplace: undefined,
    newCustomerId: undefined,
};

const offer = (state = initialState, action: OfferActionTypes): OfferState => {
    switch (action.type) {
        case RESET_OFFER_PAGE:
            return initialState;
        case CREATE_OFFER_SUCCESS:
            return { ...state, id: action.payload.id, offerData: action.payload };
        case GET_OFFER_REQUEST:
            return { ...state, withoutOfferRequest: false };
        case GET_OFFER_REQUEST_SUCCESS:
            return {
                ...state,
                offerData: {
                    ...state.offerData,
                    offerRequest: {
                        ...state.offerRequestData,
                        ...action.payload,
                    },
                },
            };
        case SAVE_FILE_SUCCESS:
            return { ...state, fileIdReplace: { tmpId: action.payload.tmpId, id: action.payload.id } };
        default:
            return state;
    }
};

export default offer;

export const getOfferRequestEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(GET_OFFER_REQUEST)),
        mergeMap((action) =>
            api.get<OfferRequestData>(`/offerRequest/${action.payload.id}`, {}, {}).pipe(
                map((res) => getOfferRequestSuccess(res)),
                catchError((error) => of(getOfferRequestError(error))),
            ),
        ),
    );

export const createOfferEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(CREATE_OFFER)),
        mergeMap(({ payload }) =>
            api.post<OfferData>(`/offer`, { ...payload }).pipe(
                map((res) => createOfferSuccess(res)),
                catchError((error) => of(createOfferError(error))),
            ),
        ),
    );

export const autoSaveEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(AUTO_SAVE)),
        mergeMap(({ payload }) =>
            api.put<OfferData>(`/offer/${state$.value.offer.id}`, { ...payload }).pipe(
                map((res) => autoSaveSuccess(res)),
                catchError((error) => of(autoSaveError(error))),
            ),
        ),
    );

export const saveFileEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(SAVE_FILE)),
        mergeMap((action) =>
            from(readFile(action.payload.file)).pipe(
                mergeMap((fileAsDataUrl) =>
                    api
                        .post<IdWrapper<string>>(`/offer/${state$.value.offer.id}/file`, {
                            data: fileAsDataUrl,
                            name: action.payload.name,
                        })
                        .pipe(
                            map((res) => saveFileSuccess(action.payload.tmpId, res)),
                            catchError((error) => of(saveFileError(error))),
                        ),
                ),
            ),
        ),
    );

export const removeFileEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(REMOVE_FILE)),
        mergeMap((action) =>
            api.delete(`/offer/${state$.value.offer.id}/file/${action.payload.id}`).pipe(
                map(() => removeFileSuccess()),
                catchError((error) => of(removeFileError(error))),
            ),
        ),
    );

export const submitOfferEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(SUBMIT_OFFER)),
        mergeMap(() =>
            api.patch(`/offer/${state$.value.offer.id}/send`).pipe(
                map(() => submitOfferSuccess()),
                catchError((error) => of(submitOfferError(error))),
            ),
        ),
    );

export const epics = combineEpics(
    getOfferRequestEpic,
    createOfferEpic,
    autoSaveEpic,
    saveFileEpic,
    removeFileEpic,
    submitOfferEpic,
);
