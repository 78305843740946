import { AjaxResponse, AjaxError } from 'rxjs/ajax';

import {
    OfferRequestData,
    MAKE_NEW_OFFER_REQUEST,
    MAKE_NEW_OFFER_REQUEST_SUCCESS,
    MAKE_NEW_OFFER_REQUEST_ERROR,
    MakeNewOfferRequestAction,
    MakeNewOfferRequestSuccessAction,
    MakeNewOfferRequestErrorAction,
    GET_SAVED_ORDERS,
    GET_SAVED_ORDERS_SUCCESS,
    GET_SAVED_ORDERS_ERROR,
    GetSavedOrderAction,
    GetSavedOrderSuccessAction,
    GetSavedOrderErrorAction,
    USE_SAVED_ORDER_ID,
    UseSavedOrderIdAction,
    AUTO_SAVE,
    AUTO_SAVE_SUCCESS,
    AUTO_SAVE_ERROR,
    AutoSaveAction,
    AutoSaveSuccessAction,
    AutoSaveErrorAction,
    SAVE_FILE,
    SAVE_FILE_SUCCESS,
    SAVE_FILE_ERROR,
    SaveFileAction,
    SaveFileSuccessAction,
    SaveFileErrorAction,
    REMOVE_FILE,
    REMOVE_FILE_ERROR,
    REMOVE_FILE_SUCCESS,
    RemoveFileAction,
    RemoveFileSuccessAction,
    RemoveFileErrorAction,
    SUBMIT_OFFER_REQUEST,
    SUBMIT_OFFER_REQUEST_SUCCESS,
    SUBMIT_OFFER_REQUEST_ERROR,
    SubmitOfferRequestAction,
    SubmitOfferRequestSuccessAction,
    SubmitOfferRequestErrorAction,
    GET_CUSTOMERS_ERROR,
    GetCustomersErrorAction,
    GetCustomersAction,
    GetCustomersSuccessAction,
    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    DownloadOfferRequestFileAction,
    DownloadOfferRequestFileSuccessAction,
    DownloadOfferRequestFileErrorAction,
    DOWNLOAD_OFFER_REQUEST_FILE,
    DOWNLOAD_OFFER_FILE_REQUEST_SUCCESS,
    DOWNLOAD_OFFER_REQUEST_FILE_ERROR,
    Customer,
} from './types';
import { IdWrapper } from '../types';
import { EmptyObject } from 'redux';

export const makeNewOfferRequest = (): MakeNewOfferRequestAction => ({
    type: MAKE_NEW_OFFER_REQUEST,
});

export const makeNewOfferRequestSuccess = (res: AjaxResponse<OfferRequestData>): MakeNewOfferRequestSuccessAction => ({
    type: MAKE_NEW_OFFER_REQUEST_SUCCESS,
    payload: {
        id: res.response.id as string,
    },
});

export const makeNewOfferRequestError = (error: AjaxError): MakeNewOfferRequestErrorAction => ({
    type: MAKE_NEW_OFFER_REQUEST_ERROR,
    error,
});

export const getSavedOrder = (): GetSavedOrderAction => ({
    type: GET_SAVED_ORDERS,
});

export const getSavedOrderSuccess = (res: AjaxResponse<OfferRequestData[]>): GetSavedOrderSuccessAction => ({
    type: GET_SAVED_ORDERS_SUCCESS,
    payload: {
        savedOrders: res.response,
    },
});

export const getSavedOrderError = (error: AjaxError): GetSavedOrderErrorAction => ({
    type: GET_SAVED_ORDERS_ERROR,
    error,
});

export const useSavedOrderId = (id: string): UseSavedOrderIdAction => ({
    type: USE_SAVED_ORDER_ID,
    payload: {
        id,
    },
});

export const autoSave = (data: OfferRequestData): AutoSaveAction => ({
    type: AUTO_SAVE,
    payload: {
        data,
    },
});

export const autoSaveSuccess = (res: AjaxResponse<OfferRequestData>): AutoSaveSuccessAction => ({
    type: AUTO_SAVE_SUCCESS,
    payload: {
        newCustomerId: res.response?.customer?.id as string,
    },
});

export const autoSaveError = (error: AjaxError): AutoSaveErrorAction => ({
    type: AUTO_SAVE_ERROR,
    error,
});

export const saveFile = (tmpId: string, file: File, name: string): SaveFileAction => ({
    type: SAVE_FILE,
    payload: {
        file,
        tmpId,
        name: name.substring(0, name.lastIndexOf('.')), // remove file ending
    },
});

export const saveFileSuccess = (tmpId: string, res: AjaxResponse<IdWrapper<string>>): SaveFileSuccessAction => ({
    type: SAVE_FILE_SUCCESS,
    payload: {
        tmpId,
        id: res.response.id,
    },
});

export const saveFileError = (error: AjaxError): SaveFileErrorAction => ({
    type: SAVE_FILE_ERROR,
    error,
});

export const removeFile = (id: string): RemoveFileAction => ({
    type: REMOVE_FILE,
    payload: {
        id,
    },
});
export const removeFileSuccess = (): RemoveFileSuccessAction => ({
    type: REMOVE_FILE_SUCCESS,
});

export const removeFileError = (error: AjaxError): RemoveFileErrorAction => ({
    type: REMOVE_FILE_ERROR,
    error,
});

export const submitOfferRequest = (): SubmitOfferRequestAction => ({
    type: SUBMIT_OFFER_REQUEST,
});

export const submitOfferRequestSuccess = (res: AjaxResponse<EmptyObject>): SubmitOfferRequestSuccessAction => ({
    type: SUBMIT_OFFER_REQUEST_SUCCESS,
    payload: res,
});

export const submitOfferRequestError = (error: AjaxError): SubmitOfferRequestErrorAction => ({
    type: SUBMIT_OFFER_REQUEST_ERROR,
    error,
});

export const getCustomers = (): GetCustomersAction => ({
    type: GET_CUSTOMERS,
});

export const getCustomersSuccess = (res: AjaxResponse<Customer[]>): GetCustomersSuccessAction => ({
    type: GET_CUSTOMERS_SUCCESS,
    payload: {
        customers: res.response,
    },
});

export const getCustomersError = (error: AjaxError): GetCustomersErrorAction => ({
    type: GET_CUSTOMERS_ERROR,
    error,
});
export const downloadOfferRequestFile = (
    offerId: string,
    fileId: string,
    name: string,
): DownloadOfferRequestFileAction => ({
    type: DOWNLOAD_OFFER_REQUEST_FILE,
    payload: {
        offerId,
        fileId,
        name,
    },
});

export const downloadOfferRequestFileSuccess = (): DownloadOfferRequestFileSuccessAction => ({
    type: DOWNLOAD_OFFER_FILE_REQUEST_SUCCESS,
});

export const downloadOfferRequestFileError = (error: AjaxError): DownloadOfferRequestFileErrorAction => ({
    type: DOWNLOAD_OFFER_REQUEST_FILE_ERROR,
    error,
});
