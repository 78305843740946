import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { OfferRequestData } from '../offerRequest/types';
import {
    AUTO_SAVE,
    AUTO_SAVE_ERROR,
    AUTO_SAVE_SUCCESS,
    AutoSaveAction,
    AutoSaveErrorAction,
    AutoSaveSuccessAction,
    CREATE_OFFER,
    CREATE_OFFER_ERROR,
    CREATE_OFFER_SUCCESS,
    CreateOfferAction,
    CreateOfferErrorAction,
    CreateOfferSuccessAction,
    GET_OFFER_REQUEST,
    GET_OFFER_REQUEST_ERROR,
    GET_OFFER_REQUEST_SUCCESS,
    GetOfferRequestAction,
    GetOfferRequestErrorAction,
    GetOfferRequestSuccessAction,
    OfferData,
    REMOVE_FILE,
    REMOVE_FILE_ERROR,
    REMOVE_FILE_SUCCESS,
    RemoveFileAction,
    RemoveFileErrorAction,
    RemoveFileSuccessAction,
    RESET_OFFER_PAGE,
    ResetOfferPageAction,
    SAVE_FILE,
    SAVE_FILE_ERROR,
    SAVE_FILE_SUCCESS,
    SaveFileAction,
    SaveFileErrorAction,
    SaveFileSuccessAction,
    SUBMIT_OFFER,
    SUBMIT_OFFER_ERROR,
    SUBMIT_OFFER_SUCCESS,
    SubmitOfferAction,
    SubmitOfferErrorAction,
    SubmitOfferSuccessAction,
} from './types';
import { IdWrapper } from '../types';

export const resetOfferPage = (): ResetOfferPageAction => ({
    type: RESET_OFFER_PAGE,
});

export const getOfferRequest = (id: string): GetOfferRequestAction => ({
    type: GET_OFFER_REQUEST,
    payload: {
        id,
    },
});

export const getOfferRequestSuccess = (res: AjaxResponse<OfferRequestData>): GetOfferRequestSuccessAction => ({
    type: GET_OFFER_REQUEST_SUCCESS,
    payload: res.response,
});

export const getOfferRequestError = (error: AjaxError): GetOfferRequestErrorAction => ({
    type: GET_OFFER_REQUEST_ERROR,
    error,
});

export const createOffer = (data: OfferData): CreateOfferAction => ({
    type: CREATE_OFFER,
    payload: data,
});

export const createOfferSuccess = (res: AjaxResponse<OfferData>): CreateOfferSuccessAction => ({
    type: CREATE_OFFER_SUCCESS,
    payload: res.response,
});

export const createOfferError = (error: AjaxError): CreateOfferErrorAction => ({
    type: CREATE_OFFER_ERROR,
    error,
});

export const autoSave = (data: OfferData): AutoSaveAction => ({
    type: AUTO_SAVE,
    payload: data,
});

export const autoSaveSuccess = (res: AjaxResponse<OfferData>): AutoSaveSuccessAction => ({
    type: AUTO_SAVE_SUCCESS,
});

export const autoSaveError = (error: AjaxError): AutoSaveErrorAction => ({
    type: AUTO_SAVE_ERROR,
    error,
});

export const saveFile = (tmpId: string, file: File, name: string): SaveFileAction => ({
    type: SAVE_FILE,
    payload: {
        file,
        tmpId,
        name: name.substring(0, name.lastIndexOf('.')), // remove file ending
    },
});

export const saveFileSuccess = (tmpId: string, res: AjaxResponse<IdWrapper<string>>): SaveFileSuccessAction => ({
    type: SAVE_FILE_SUCCESS,
    payload: {
        tmpId,
        id: res.response.id,
    },
});

export const saveFileError = (error: AjaxError): SaveFileErrorAction => ({
    type: SAVE_FILE_ERROR,
    error,
});

export const removeFile = (id: string): RemoveFileAction => ({
    type: REMOVE_FILE,
    payload: {
        id,
    },
});
export const removeFileSuccess = (): RemoveFileSuccessAction => ({
    type: REMOVE_FILE_SUCCESS,
});

export const removeFileError = (error: AjaxError): RemoveFileErrorAction => ({
    type: REMOVE_FILE_ERROR,
    error,
});

export const submitOffer = (): SubmitOfferAction => ({
    type: SUBMIT_OFFER,
});

export const submitOfferSuccess = (): SubmitOfferSuccessAction => ({
    type: SUBMIT_OFFER_SUCCESS,
});

export const submitOfferError = (error: AjaxError): SubmitOfferErrorAction => ({
    type: SUBMIT_OFFER_ERROR,
    error,
});
