import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import OfferRequestList from '../../components/offer-request-list';

import { getCustomers, getOfferRequests, selectCustomer } from '../../redux/customer-offers/actions';

import { Customer } from '../../redux/customer-offers/types';
import { Role } from '../../redux/user/types';
import { RootState } from '../../redux';

const CustomerOffers: FC = () => {
    const dispatch = useDispatch();

    const { customers, offerRequests, selectedCustomer } = useSelector((state: RootState) => state.customerOffers);
    const role = useSelector((state: RootState) => state.user.role);
    const userId = useSelector((state: RootState) => state.user.id);

    useEffect(() => {
        document.title = 'Tarjouspyyntöportaali - Tehdyt tarjouspyynnöt ja tarjoukset!';
    }, []);

    useEffect(() => {
        dispatch(getCustomers());
    }, []);

    useEffect(() => {
        if (selectedCustomer) return;
        const customer = customers.find(({ user }) => user === userId);
        if (customer) dispatch(selectCustomer(customer.id));
    }, [selectedCustomer, customers]);

    useEffect(() => {
        if (!selectedCustomer) return;
        dispatch(getOfferRequests());
    }, [selectedCustomer]);

    const handleCustomerSelect = (e: SelectChangeEvent<Customer['id']>) => {
        dispatch(selectCustomer(e.target.value));
    };

    return (
        <Container maxWidth='xl'>
            <Box mt={2} mb={1}>
                {(role === Role.ADMIN || role === Role.STAFF) && customers.length > 0 && (
                    <Grid container alignItems='center' justifyContent='center'>
                        <FormControl>
                            <InputLabel id='customer-label' shrink={true}>
                                Asiakas
                            </InputLabel>
                            <Select
                                id='customer'
                                labelId='customer-label'
                                value={selectedCustomer ?? customers[0].id}
                                onChange={handleCustomerSelect}>
                                {customers.map((c) => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid container spacing={2} alignItems='center' justifyContent='center'>
                    <Grid item xs={9}>
                        <Typography variant='h1' component='h1'>
                            Vireillä olevat tarjouspyynnöt
                        </Typography>
                        <OfferRequestList offerRequests={offerRequests.filter(({ status }) => status !== 'DONE')} />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant='h1' component='h1'>
                            Saadut tarjoukset
                        </Typography>
                        <OfferRequestList offerRequests={offerRequests.filter(({ status }) => status === 'DONE')} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default CustomerOffers;
